<template>
  <div :class="`notification-list${fullSize ? ' full-size' : ''}`">
    <div class="notification-header">
      <div class="notification-header-title">Notifications</div>
      <div class="notification-header-actions">
        <button type="button" class="icon-link" @click.stop="$emit('markAsRead')" :disabled="!hasUnread">
          <span class="material-icons">done_all</span>
          <span>Mark all as read</span>
        </button>
        <button v-if="fullSize && !hideSettings" type="button" class="icon-link secondary" @click.stop="$router.push('/account/notifications/settings')" :disabled="!hasUnread">
          <span class="material-icons">settings</span>
          <span>Settings</span>
        </button>
      </div>
    </div>
    <router-link :to="notification.path" :class="`notification-item${notification.read ? '' : ' unread'}`" v-for="notification in (fullSize ? list : list.slice(0, 3))" :key="notification.id">
      <img v-if="notification.image" :src="notification.image" alt="" class="notification-item-avatar">
      <div v-else class="notification-item-avatar"></div>
      <div class="notification-item-message">{{ notification.message }}</div>
    </router-link>
    <div class="notification-item empty" v-if="!notifications.length">
      <div class="notification-item-message">You have no notifications just yet...</div>
    </div>
    <router-link v-if="!fullSize" class="notification-item all" to="/account/notifications">
      <div class="notification-item-message">
        View all notifications
        <span v-if="unreadCount">({{ unreadCount }} unread)</span>
      </div>
    </router-link>
  </div>
</template>

<script lang="ts" setup>
import { computed, defineProps, defineEmits } from 'vue';
import { useStore } from 'vuex';
import { NotificationType } from '../types/Notification';

const store = useStore();

const props = defineProps<{
  notifications: any[],
  lastRead: Date | null,
  hasUnread: boolean,
  fullSize?: boolean,
  hideSettings?: boolean,
}>();

defineEmits(['markAsRead']);

const list = computed(() => props.notifications.map((notification) => {
  const read = props.lastRead && notification.created_at <= props.lastRead;
  switch (notification.type) {
    case NotificationType.AccountCreated:
      return {
        id: notification.id,
        message: 'Welcome to Juicy Music Square! Don\'t forget to complete your profile and verify your email address.',
        image: '/images/ic-music.svg',
        path: '/account',
        read,
      };
    case NotificationType.AccountUpgraded:
      return {
        id: notification.id,
        message: `Congratulations! You are not listed as ${notification.details.account_type === 'venue' ? 'a venue' : 'an artist'} on Juicy Music Square.`,
        image: `/images/ic-${notification.details.account_type}.svg`,
        path: '/account',
        read,
      };
    case NotificationType.EventArtistAdded:
      return {
        id: notification.id,
        message: `Congrats! You are now listed as playing at ${notification.details.event_name}.`,
        image: '/images/ic-calendar.svg',
        path: `/events/${notification.details.event_id}/${notification.details.event_slug}`,
        read,
      };
    case NotificationType.EventAttendeeAdded:
      return {
        id: notification.id,
        message: `${notification.details.user_name} joined your event "${notification.details.event_name}"!`,
        image: notification.details.user_avatar || '/images/ic-person.svg',
        path: `/events/${notification.details.event_id}/${notification.details.event_slug}`,
        read,
      };
    case NotificationType.EventArtistRemoved:
      return {
        id: notification.id,
        message: `You have been removed from the list of artists playing at ${notification.details.event_name}.`,
        image: '/images/ic-calendar.svg',
        path: `/events/${notification.details.event_id}/${notification.details.event_slug}`,
        read,
      };
    case NotificationType.GroupCreated:
      return {
        id: notification.id,
        message: `Your band ${notification.details.group_name} was created! Don't forget to invite your band members to join it.`,
        image: '/images/ic-music.svg',
        path: `/account/bands/${notification.details.group_id}`,
        read,
      };
    case NotificationType.RequestReceived:
      return {
        id: notification.id,
        message: notification.details.sender_type === 'venue'
          ? `${notification.details.sender_name} invited you to play at their event "${notification.details.event_name}"!`
          : `${notification.details.sender_name} requested to play at your event "${notification.details.event_name}"!`,
        image: notification.details.sender_avatar || '/images/ic-music.svg',
        path: '/account/requests',
        read,
      };
    case NotificationType.RequestDeclined:
      return {
        id: notification.id,
        message: `${notification.details.user_name} has declined your invitation to play at "${notification.details.event_name}"!`,
        image: notification.details.user_avatar || '/images/ic-music.svg',
        path: '/account/requests',
        read,
      };
    case NotificationType.RequestAccepted:
      return {
        id: notification.id,
        message: `${notification.details.user_name} has accepted your invitation to play at "${notification.details.event_name}"!`,
        image: notification.details.user_avatar || '/images/ic-music.svg',
        path: '/account/requests',
        read,
      };
    case NotificationType.PaymentReceived:
      return {
        id: notification.id,
        message: `${notification.details.sender_name} sent a payment of ${store.state.currencies[notification.details.currency]}${notification.details.amount}!`,
        image: '/images/ic-payments.svg',
        path: '/account/payouts',
        read,
      };
    case NotificationType.PayoutSent:
      return {
        id: notification.id,
        message: `A payout of ${store.state.currencies[notification.details.currency]}${notification.details.amount} is on its way!`,
        image: '/images/ic-payments.svg',
        path: '/account/payouts',
        read,
      };
    case NotificationType.GroupRequestDeclined:
      return {
        id: notification.id,
        message: `${notification.details.artist_name} has declined your invitation to join your band "${notification.details.group_name}"!`,
        image: notification.details.artist_avatar || '/images/ic-music.svg',
        path: `/account/bands/${notification.details.group_id}`,
        read,
      };
    case NotificationType.GroupMemberAdded:
      return {
        id: notification.id,
        message: `${notification.details.artist_name} has joined your band "${notification.details.group_name}"!`,
        image: notification.details.artist_avatar || '/images/ic-music.svg',
        path: `/account/bands/${notification.details.group_id}`,
        read,
      };
    case NotificationType.EventCreated:
      return {
        id: notification.id,
        message: `Your event ${notification.details.event_name} was created! Don't forget to invite artists to play there!`,
        image: '/images/ic-calendar.svg',
        path: `/account/events/${notification.details.event_id}`,
        read,
      };
    case NotificationType.ReviewReceived: {
      let path = '';
      let target = 'You have';
      switch (notification.details.target_type) {
        case 'artist':
          path = `/artists/${notification.details.target_slug}`;
          break;
        case 'venue':
          path = `/venues/${notification.details.target_slug}`;
          break;
        case 'group':
          path = `/bands/${notification.details.target_slug}`;
          target = `Your band "${notification.details.group_name}" has`;
          break;
      }
      return {
        id: notification.id,
        message: `${target} received a new review from ${notification.details.user_name}!`,
        image: notification.details.user_avatar || '/images/ic-person.svg',
        path,
        read,
      };
    }
    case NotificationType.LikeReceived: {
      let path = '';
      let target = 'profile';
      switch (notification.details.target_type) {
        case 'artist':
          path = `/artists/${notification.details.target_slug}`;
          break;
        case 'venue':
          path = `/venues/${notification.details.target_slug}`;
          break;
        case 'group':
          path = `/bands/${notification.details.target_slug}`;
          target = `band "${notification.details.group_name}"`;
          break;
        case 'event':
          path = `/events/${notification.details.event_id}/${notification.details.event_slug}`;
          target = `event "${notification.details.event_name}"`;
          break;
      }
      return {
        id: notification.id,
        message: `${notification.details.user_name} liked your ${target}!`,
        image: notification.details.user_avatar || '/images/ic-person.svg',
        path,
        read,
      };
    }
    default:
      return null;
  }
}).filter((n) => !!n));

const unreadCount = computed(() => {
  return list.value.filter((notification) => !notification.read).length;
});
</script>

<style scoped>
.notification-item {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 6px;
  padding: 12px 16px;
}

.notification-item.empty,
.notification-item.all {
  color: var(--text-color-light);
  justify-content: center;
  text-align: center;
}

.notification-item.all {
  color: var(--text-highlight-color);
  font-weight: 600;
}

.notification-item:not(.empty):hover {
  background-color: var(--background-lighter-opacity);
}

.notification-item.unread {
  background-color: var(--tab-highlight-color);
  font-weight: 600;
}

.notification-item:last-child {
  margin-bottom: 0;
}

.full-size .notification-item {
  background-image: url(../assets/jms-bg-dashed.svg);
  background-repeat: repeat-x;
  background-position: left top;
  background-size: auto;
  padding: 16px;
  margin-bottom: 0;
}

.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 8px 16px;
  margin-bottom: 6px;
  font-weight: 600;
}

.notification-header .notification-header-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
}

.icon-link {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  font-size: 0.875rem;
  padding: 4px 8px;
  color: var(--text-highlight-color);
}

.icon-link.secondary {
  color: var(--text-color);
}

.icon-link:not([disabled]):hover {
  background: var(--tab-highlight-color);
}

.icon-link[disabled] {
  opacity: 0.33;
  cursor: default;
}

.icon-link .material-icons {
  font-size: 1rem;
}

.notification-item .notification-item-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: var(--background-light);
  flex-shrink: 0;
}

.notification-item .notification-item-avatar[src^="/"] {
  padding: 4px;
}

.notification-item .notification-item-message {
  font-size: 0.875rem;
}
</style>
