export enum NotificationType {
  AccountCreated = 'account-created',
  AccountUpgraded = 'account-upgraded',

  EventArtistAdded = 'event-artist-added',
  EventArtistRemoved = 'event-artist-removed',
  EventAttendeeAdded = 'event-attendee-added',
  EventCreated = 'event-created',

  GroupCreated = 'group-created',
  GroupMemberAdded = 'group-member-added',
  GroupMemberRemoved = 'group-member-removed',
  GroupRequestAccepted = 'group-request-accepted',
  GroupRequestCreated = 'group-request-created',
  GroupRequestDeclined = 'group-request-declined',
  GroupRequestReceived = 'group-request-received',

  LikeReceived = 'like-received',

  PaymentReceived = 'payment-received',
  PayoutSent = 'payout-sent',

  RequestAccepted = 'request-accepted',
  RequestConfirmed = 'request-confirmed',
  RequestDeclined = 'request-declined',
  RequestNegotiated = 'request-negotiated',
  RequestReceived = 'request-received',
  RequestSent = 'request-sent',

  ReviewReceived = 'review-received',

  SubscriptionCanceled = 'subscription-canceled',
  SubscriptionCreated = 'subscription-created',
}
