import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/HomeView.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/dev-log',
    name: 'dev-log',
    component: () => import(/* webpackChunkName: "dev-log" */ '../views/TemplatePageView.vue'),
  },
  {
    path: '/venues',
    name: 'venues',
    component: () => import(/* webpackChunkName: "venues" */ '../views/VenuesView.vue'),
  },
  {
    path: '/venues/:slug',
    name: 'venue',
    component: () => import(/* webpackChunkName: "venue" */ '../views/VenueView.vue'),
  },
  {
    path: '/events',
    name: 'events',
    component: () => import(/* webpackChunkName: "events" */ '../views/EventsView.vue'),
  },
  {
    path: '/artists',
    name: 'artists',
    component: () => import(/* webpackChunkName: "artists" */ '../views/ArtistsView.vue'),
  },
  {
    path: '/artists/:slug',
    name: 'artist',
    component: () => import(/* webpackChunkName: "artist" */ '../views/ArtistView.vue'),
  },
  {
    path: '/bands',
    name: 'bands',
    component: () => import(/* webpackChunkName: "bands" */ '../views/GroupsView.vue'),
  },
  {
    path: '/bands/:slug',
    name: 'band',
    component: () => import(/* webpackChunkName: "band" */ '../views/GroupView.vue'),
  },
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsView.vue'),
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: () => import(/* webpackChunkName: "pricing" */ '../views/PricingView.vue'),
  },
  {
    path: '/events/new',
    name: 'event-new',
    component: () => import(/* webpackChunkName: "event-new" */ '../views/EventEditView.vue'),
  },
  {
    path: '/events/:id/:slug',
    name: 'event',
    component: () => import(/* webpackChunkName: "event" */ '../views/EventView.vue'),
  },
  {
    path: '/news/:slug',
    name: 'post',
    component: () => import(/* webpackChunkName: "post" */ '../views/BlogPostView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue'),
  },
  {
    path: '/signup',
    name: 'signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/SignUpView.vue'),
  },
  {
    path: '/verify-email',
    name: 'verify-email',
    component: () => import(/* webpackChunkName: "verify-email" */ '../views/VerifyEmailView.vue'),
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: () => import(/* webpackChunkName: "reset-password" */ '../views/ResetPasswordView.vue'),
  },
  {
    path: '/leaderboard/:slug',
    name: 'leaderboard',
    component: () => import(/* webpackChunkName: "leaderboard" */ '../views/LeaderboardView.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: () => import(/* webpackChunkName: "account" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/upgrade/:plan/:id',
    name: 'account-upgrade',
    component: () => import(/* webpackChunkName: "account-upgrade" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/requests',
    name: 'account-requests',
    component: () => import(/* webpackChunkName: "account-requests" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/pro',
    name: 'account-pro',
    component: () => import(/* webpackChunkName: "account-pro" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/notifications',
    name: 'account-notifications',
    component: () => import(/* webpackChunkName: "account-notifications" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/notifications/settings',
    name: 'account-notifications-settings',
    component: () => import(/* webpackChunkName: "account-notifications-settings" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/billing',
    name: 'account-billing',
    component: () => import(/* webpackChunkName: "account-billing" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/feedback',
    name: 'account-feedback',
    component: () => import(/* webpackChunkName: "account-feedback" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/payments',
    name: 'account-payments',
    component: () => import(/* webpackChunkName: "account-payments" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/payouts',
    name: 'account-payouts',
    component: () => import(/* webpackChunkName: "account-payouts" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/band-requests',
    name: 'account-band-requests',
    component: () => import(/* webpackChunkName: "account-band-requests" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/events',
    name: 'account-events',
    component: () => import(/* webpackChunkName: "account-events" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/events/:id',
    name: 'account-event',
    component: () => import(/* webpackChunkName: "account-event" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/bands',
    name: 'account-bands',
    component: () => import(/* webpackChunkName: "account-bands" */ '../views/AccountView.vue'),
  },
  {
    path: '/account/bands/:id',
    name: 'account-band',
    component: () => import(/* webpackChunkName: "account-band" */ '../views/AccountView.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from) {
    if (from?.fullPath !== to.fullPath) {
      (window as any).ga('send', 'pageview');
    }
    const hasTouch = ('ontouchstart' in document.documentElement);
    const butter = document.querySelector('#butter');
    if (!hasTouch && butter) {
      (window as any).butter.cancel();
    }
    window.scrollTo({ top: 0 });
    if (!hasTouch && butter) {
      (window as any).butter.init();
    }
  },
});

export default router;
