<template>
  <section class="events">
    <div class="wrapper">
      <div class="section-header">
        <div class="section-title icon">
          <img src="@/assets/jms-ic-events.svg" alt="" class="section-icon">
          <h2>Upcoming events & gigs</h2>
          <div class="section-title-line">Find your favorite gigs!</div>
          <div class="section-title-note" v-if="eventsCount">
            <NumberFormat :value="eventsCount" />
            events & gigs
          </div>
        </div>
        <SelectInput v-model="selectedYear" :options="years" />
      </div>
      <div class="calendar-controls">
        <div class="calendar-months">
          <button class="btn month" :selected="(selectedMonth === index) || null" v-for="(month, index) in months" @click="selectedMonth = index" :key="month">{{ month }}</button>
        </div>
        <div class="calendar-days">
          <button :class="`btn day${eventsMap[`${selectedYear}-${selectedMonth}-${day.number}`]?.length ? '' : ' empty'}${isToday(new Date(selectedYear, selectedMonth, day.number)) ? ' current' : ''}`" :style="{ width: `calc(${100 / days.length}% - ${6 * (days.length - 1) / days.length}px)` }" :selected="(selectedDate === day.number) || null" v-for="day in days" @click="selectedDate = day.number" :key="day.number">
            <span class="day-label">{{ day.day }}</span>
            <span class="day-number">{{ day.number }}</span>
          </button>
        </div>
      </div>
    </div>
    <div class="calendar-container" v-if="eventsMap[`${selectedYear}-${selectedMonth}-${selectedDate}`]?.length">
      <div class="wrapper">
        <div class="calendar-events">
          <div class="calendar-item" v-for="event in eventsMap[`${selectedYear}-${selectedMonth}-${selectedDate}`]" :key="event.id">
            <EventItem :event="event" :like="likes.find((like) => like.target.id === event.id)" />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup>
import { computed, onBeforeUnmount, onMounted, ref, watch, defineProps, withDefaults } from 'vue';
import { format, getDaysInMonth, lastDayOfMonth, isToday } from 'date-fns';
import { Unsubscribe, collection, getCountFromServer, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { db } from '@/api';
import SelectInput from './SelectInput.vue';
import EventItem from './EventItem.vue';
import NumberFormat from './NumberFormat.vue';

withDefaults(defineProps<{ likes: any[] }>(), { likes: () => [] });

const eventsRef = ref<Unsubscribe>();
const events = ref<any[]>([]);
const eventsCount = ref(+(window.localStorage.getItem('eventsCount') || 0));
const selectedYear = ref(new Date().getFullYear());
const selectedMonth = ref(new Date().getMonth());
const selectedDate = ref(new Date().getDate());
const months = ref(['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']);

const years = computed(() => {
  const years: any[] = [];

  for (let i = new Date().getFullYear(); i >= 2024; i--) {
    years.push({ label: i, value: i });
  }

  return years;
});

const days = computed(() => {
  const totalDays = getDaysInMonth(new Date(selectedYear.value, selectedMonth.value));
  const days: any[] = [];

  for (let i = 1; i <= totalDays; i++) {
    const date = new Date(selectedYear.value, selectedMonth.value, i);
    days.push({
      number: i,
      date,
      day: format(date, 'EEE'),
    });
  }

  return days;
});

const eventsMap = computed(() => {
  return events.value.reduce((map, event) => {
    const key = `${event.start_date.getFullYear()}-${event.start_date.getMonth()}-${event.start_date.getDate()}`;
    if (!map[key]) {
      map[key] = [];
    }
    map[key].push(event);
    return map;
  }, {});
});

const getEvents = () => {
  events.value = [];
  eventsRef.value?.();
  const start = new Date(selectedYear.value, selectedMonth.value, 1);
  const end = lastDayOfMonth(start);
  end.setHours(23);
  end.setMinutes(59);
  end.setSeconds(59);
  end.setMilliseconds(999);
  eventsRef.value = onSnapshot(query(collection(db, 'events'), where('active', '==', true), where('start_date', '>=', start), where('start_date', '<=', end), orderBy('start_date', 'asc')), (snap) => {
    let eventsToday = false;
    events.value = snap.docs.map((doc) => {
      const data = doc.data();
      const startDate = data.start_date.toDate();
      if (isToday(startDate)) {
        eventsToday = true;
      }
      return {
        ...data,
        start_date: startDate,
      };
    });
    selectedDate.value = (eventsToday ? new Date().getDate() : events.value[0]?.start_date.getDate()) || 1;
  });
};

const getEventsCount = async () => {
  const result = await getCountFromServer(query(collection(db, 'events'), where('active', '==', true)));
  eventsCount.value = result.data().count;
  window.localStorage.setItem('eventsCount', `${eventsCount.value}`);
};

watch([selectedYear, selectedMonth], () => {
  getEvents();
});

onMounted(() => {
  getEvents();
  getEventsCount();
});

onBeforeUnmount(() => {
  eventsRef.value?.();
});
</script>

<style scoped>
.events {
  position: relative;
  background: var(--background-light);
  padding-top: 24px;
}

.events select {
  background: transparent;
  font: inherit;
  color: #fff;
  border: none;
}

.events .section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.events .section-title {
  margin-bottom: 0;
}

.events .section-title .section-title-note::before {
  background: var(--text-color);
}

.events .calendar-months {
  display: flex;
  justify-content: stretch;
  gap: 6px;
  padding-bottom: 4px;
  margin-bottom: 8px;
  position: relative;
  overflow: auto;
}

.events .calendar-months .month {
  padding: 0 8px;
  flex-grow: 1;
  color: var(--text-color-light);
  border-color: var(--border-color-light);
}

.events .calendar-months .month[selected] {
  background: var(--jms-orange);
  border-color: var(--jms-orange);
  font-weight: bold;
  color: var(--text-color);
}

.events .calendar-days {
  display: flex;
  justify-content: stretch;
  gap: 2px;
  position: relative;
  overflow: auto;
  padding-bottom: 20px;
}

.events .calendar-months::-webkit-scrollbar,
.events .calendar-days::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 3px;
}

.events .calendar-months::-webkit-scrollbar-track,
.events .calendar-days::-webkit-scrollbar-track {
  border-radius: 3px;
  background: var(--background-dark-opacity);
}

.events .calendar-months::-webkit-scrollbar-thumb,
.events .calendar-days::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: var(--background-darker);
}

.events .calendar-days .day {
  position: relative;
  flex-direction: column;
  padding: 0;
  height: auto;
  flex-grow: 1;
  border: none;
  gap: 6px;
  font-size: 1.125rem;
  min-width: 32px;
}

.events .calendar-days .day:hover {
  background: transparent;
}

.events .calendar-days .day.current::before {
  content: '';
  position: absolute;
  top: -4px;
  left: 0;
  right: 0;
  height: 84px;
  background: var(--border-color-light);
  pointer-events: none;
}

.events .calendar-days .day.empty {
  opacity: 0.46;
  pointer-events: none;
}

.events .calendar-days .day .day-label {
  font-weight: 500;
  font-size: 0.875rem;
}

.events .calendar-days .day[selected] .day-label {
  font-weight: bold;
}

.events .calendar-days .day .day-number {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 40px;
  background: var(--jms-light-gray);
  border: 1px solid var(--jms-light-gray);
  color: var(--background-light);
  font-weight: bold;
  transition: all 0.08s linear;
}

.events .calendar-days .day:hover .day-number {
  transform: scale(1.15);
  z-index: 1;
  box-shadow: 0 0 6px 2px var(--box-shadow-opacity);
}

.events .calendar-days .day.empty .day-number {
  background: transparent;
  color: var(--text-color);
}

.events .calendar-days .day[selected] .day-number {
  background: var(--jms-orange);
  border-color: var(--jms-orange);
  font-weight: bold;
  color: var(--text-color);
}

.calendar-container {
  background: var(--background-dark);
  padding-top: 32px;
}

.calendar-events {
  display: flex;
  flex-wrap: wrap;
  gap: 40px 12px;
}

.calendar-events .calendar-item {
  width: calc(33.3333333% - 8px);
}

@media screen and (max-width: 1024px) {
  .calendar-events .calendar-item {
    width: calc(50% - 6px);
  }
}

@media screen and (max-width: 800px) {
  .calendar-events .calendar-item {
    width: 100%;
  }
  .select-container {
    position: absolute;
    top: 24px;
    right: 24px;
  }
}
</style>
