import { User } from 'firebase/auth';
import { isProd } from '.';

export class APIClient {
  baseUrl = isProd ? 'https://juicy-music-square-prod.web.app/api/v1' : 'https://juicymusicsquare.web.app/api/v1';

  user: User | null = null;

  setUser(user: User | null) {
    this.user = user;
  }

  async request(path: string, options = {}) {
    const token = await this.user?.getIdToken();
    const response = await fetch(`${this.baseUrl}${path}`, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: token || '',
      },
      ...options,
    });
    if (response.ok) {
      return response.json();
    }
    const error = await response.json();
    throw error;
  }

  get(path: string, params = {}) {
    const qs = new URLSearchParams(params).toString();
    return this.request(`${path}${qs ? `?${qs}` : ''}`, {
      method: 'GET',
    });
  }

  post(path: string, body?: Record<string, any>) {
    return this.request(path, {
      method: 'POST',
      body: body && JSON.stringify(body),
    });
  }

  patch(path: string, body?: Record<string, any>) {
    return this.request(path, {
      method: 'PATCH',
      body: body && JSON.stringify(body),
    });
  }

  delete(path: string, params = {}) {
    const qs = new URLSearchParams(params).toString();
    return this.request(`${path}${qs ? `?${qs}` : ''}`, {
      method: 'DELETE',
    });
  }
}

export const client = new APIClient();
