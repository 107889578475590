<template>
  <form id="stripe-form" :class="loading || disabled ? 'disabled' : ''" @submit.prevent="submitPayment">
    <div class="payment-methods" v-if="paymentMethods.length">
      <div class="payment-methods-title">Your payment methods</div>
      <div class="list">
        <div class="payment-method" v-for="paymentMethod in paymentMethods" :key="paymentMethod.id">
          <CheckBox
            size="l"
            :disabled="paymentMethod.card.exp_year < date.getFullYear() || (paymentMethod.card.exp_year === date.getFullYear() && paymentMethod.card.exp_month < date.getMonth() + 1)"
            :model-value="selectPaymentMethod === paymentMethod.id"
            @change="selectPaymentMethod = $event ? paymentMethod.id : ''"
          />
          <div class="payment-method-content">
            <div class="payment-method-title"><span class="brand">{{ paymentMethod.card.brand }}</span> ending with {{ paymentMethod.card.last4 }}</div>
            <div class="payment-method-info">Expires on {{ paymentMethod.card.exp_month }}/{{ paymentMethod.card.exp_year }}</div>
          </div>
        </div>
      </div>
    </div>
    <div id="card-element" :style="{ display: selectPaymentMethod ? 'none' : 'block' }"></div>
    <div class="field">
      <label for="discount">Discount code</label>
      <input id="discount" name="discount" placeholder="Enter a discount code..." v-model="discountCodeValue" type="text" class="input" maxlength="48" @input="validateDiscountCode">
      <div class="loader" v-if="loadingDiscount"></div>
    </div>
    <div class="success-message" v-if="discountCode"><b>Discount:</b> {{ discountCode.description }}</div>
    <div class="error" v-if="error">{{ error }}</div>
    <button type="submit" class="btn orange" :disabled="loading || disabled">{{ buttonText || 'Subscribe' }}</button>
    <img src="@/assets/powered-by-stripe.svg" alt="" class="powered-by">
  </form>
</template>

<script lang="ts" setup>
import { onMounted, ref, defineEmits, defineProps } from 'vue';
import { isProd } from '@/api';
import { client } from '@/api/api';
import CheckBox from '@/components/CheckBox.vue';

const stripe = (window as any).Stripe(isProd ? 'pk_live_LFH1vGfsg8MWUfZ57mV9OVbI' : 'pk_test_GDEz4mI1Yge7AA6PcQgaMQUB');
const elements = stripe.elements();

const emit = defineEmits(['payment']);

defineProps<{ disabled?: boolean, buttonText?: string }>();

const cardElement = ref();
const error = ref('');
const loading = ref(false);
const paymentMethods = ref<any[]>([]);
const selectPaymentMethod = ref('');
const discountCode = ref<any>(null);
const discountCodeValue = ref('');
const discountTO = ref();
const loadingDiscount = ref(false);
const date = ref(new Date());

const submitPayment = () => {
  if (loading.value) {
    return;
  }
  if (selectPaymentMethod.value) {
    emit('payment', {
      paymentMethod: selectPaymentMethod.value,
      discountCode: discountCodeValue.value,
    });
    return;
  }
  loading.value = true;
  error.value = '';
  stripe.createPaymentMethod('card', cardElement.value).then((result: any) => {
    if (result.error) {
      error.value = result.error.message;
    } else {
      emit('payment', {
        paymentMethod: result.paymentMethod.id,
        discountCode: discountCodeValue.value,
      });
    }
    loading.value = false;
  });
};

const getPaymentMethods = async () => {
  paymentMethods.value = await client.get('/users/me/payment-methods');
};

const validateDiscountCode = async () => {
  discountCode.value = null;
  loadingDiscount.value = false;
  error.value = '';
  clearTimeout(discountTO.value);
  setTimeout(async () => {
    try {
      loadingDiscount.value = true;
      const result = await client.post('/discount-codes/validate', {
        code: discountCodeValue.value,
      });
      if (result.code === discountCodeValue.value) {
        discountCode.value = result;
      }
    } catch (err: any) {
      console.warn(err);
      error.value = err.message;
    }
    loadingDiscount.value = false;
  }, 1000);
};

onMounted(() => {
  getPaymentMethods();
  cardElement.value = elements.create('card', {
    hidePostalCode: true,
  });
  cardElement.value.mount('#card-element');
});
</script>

<style scoped>
#card-element {
  padding: 12px 16px;
  background: #fff;
  margin: 16px 0;
}

#card-element.disabled {
  opacity: 0.33;
  pointer-events: none;
}

.btn {
  width: 100%;
}

.powered-by {
  width: 152px;
  margin: 24px auto 0;
}

.payment-methods {
  margin-bottom: 24px;
}

.payment-methods-title {
  font-weight: 500;
  margin-bottom: 12px;
}

.payment-method {
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 500;
}

.payment-method-info {
  opacity: 0.69;
  font-size: 0.875rem;
  margin-top: 4px;
}

.error,
.success-message {
  margin-bottom: 16px;
}

.success-message {
  background: var(--text-highlight-color);
}

.field {
  position: relative;
}

.field .loader {
  position: absolute;
  bottom: 4px;
  right: 0;
}
</style>
