<template>
  <section class="promote">
    <div class="wrapper">
      <div class="container">
        <img class="promote-image" src="@/assets/jmg-bg-home-promote.jpg" alt="">
        <div class="promote-content">
          <div class="section-title red">
            <h2>Boost Your Visibility <br>with Juicy Music Square</h2>
          </div>
          <p>Calling all venues, solo artists, and bands! Join Juicy Music Square and skyrocket your visibility! Be featured on our vibrant grid of events and gigs in Galway. This is your chance to increase your popularity and fan base! Don't miss out on this golden opportunity to shine in the music scene!</p>
          <router-link class="btn red" to="/signup">Join Juicy Music Square</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts" setup></script>

<style scoped>
.promote {
  background: var(--background-darker);
}

.promote .section-title {
  margin-left: -16px;
  margin-bottom: 24px;
}

.promote .container {
  display: flex;
  align-items: center;
}

.promote .promote-image {
  width: 50%;
  height: 546px;
  object-fit: cover;
}

.promote .promote-content {
  width: 50%;
  padding: 24px 72px 24px 96px;
}

.promote p {
  line-height: 1.6;
  font-size: 1.125rem;
  margin-bottom: 24px;
}

@media screen and (max-width: 1024px) {
  .promote .wrapper {
    padding: 0;
  }
  .promote .promote-content {
    padding: 24px 48px;
  }
  .promote .promote-image {
    height: auto;
    align-self: stretch;
  }
}

@media screen and (max-width: 840px) {
  .promote .container {
    display: block;
  }
  .promote .promote-image,
  .promote .promote-content {
    width: 100%;
    height: auto;
  }
  .promote .promote-content {
    padding: 24px 32px;
  }
}
</style>
