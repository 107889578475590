<template>
  <button :class="`checkbox-container${label ? ' has-label' : ''}`" type="button" :disabled="disabled" @click="emit('update:modelValue', !modelValue); emit('change', !modelValue)">
    <span :class="`checkbox ${size || 'default'}${modelValue ? ' checked' : ''}`">
      <span class="material-icons" v-if="modelValue">done</span>
    </span>
    <input type="checkbox" :checked="modelValue" :required="required">
    <span v-if="label" class="label">{{ label }}</span>
  </button>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits } from 'vue';

defineProps<{
  modelValue: boolean;
  size?: 'default' | 'l';
  label?: string;
  required?: boolean;
  disabled?: boolean;
}>();

const emit = defineEmits(['update:modelValue', 'change']);
</script>

<style scoped>
.checkbox-container {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  padding: 0;
}

.checkbox-container.has-label {
  display: flex;
}

.checkbox-container[disabled] {
  opacity: 0.33;
  cursor: default;
}

.checkbox {
  position: relative;
  display: inline-flex;
  width: 12px;
  height: 12px;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: var(--background-light);
}

.checkbox.l {
  width: 18px;
  height: 18px;
}

.checkbox.checked {
  background: var(--jms-yellow);
  color: var(--background-dark);
}

.checkbox[disabled] {
  opacity: 0.33;
  cursor: default;
}

.checkbox .material-icons {
  font-size: 0.625rem;
}

.checkbox.l .material-icons {
  font-size: 0.875rem;
}

.label {
  opacity: 0.69;
  font-size: 0.875rem;
  font-weight: 500;
}

input {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  pointer-events: none;
}
</style>
