<template>
  <span :class="`icon ${size || 'default'}`" v-html="svg"></span>
</template>

<script lang="ts" setup>
import { defineProps, onMounted, ref } from 'vue';

const _window = window as any;

const getCachedIcon = (name: string) => {
  if (!_window.ICON_CACHE) {
    _window.ICON_CACHE = {};
  }
  return _window.ICON_CACHE[name];
};

const props = defineProps<{
  name: 'x' | 'music' | 'link' | 'view' | 'pin' | 'google' | 'facebook' | 'bell' | 'calendar' |
  'person-circle' | 'currency' | 'users' | 'request' | 'fb' | 'instagram' | 'tiktok' | 'snapchat' |
  'web' | 'question' | 'logout' | 'close' | 'expired' | 'declined' | 'pending' | 'confirm' | 'delete' |
  'edit' | 'artist' | 'venue' | 'band' | 'filter' | 'sort' | 'chevron-down' | 'payments' | 'feedback';
  size?: 'xs' | 's' | 'l' | 'default';
}>();

const svg = ref();

const getIcon = async () => {
  const cachedIcon = getCachedIcon(props.name);
  if (cachedIcon) {
    svg.value = cachedIcon;
    return;
  }
  const data = await fetch(`/images/ic-${props.name}.svg`);
  svg.value = await data.text();
  _window.ICON_CACHE[props.name] = svg.value;
};

onMounted(() => {
  getIcon();
});
</script>

<style scoped>
.icon {
  display: inline-block;
  vertical-align: middle;
  width: 1.5rem;
  height: 1.5rem;
}

.icon.xs {
  width: 1rem;
  height: 1rem;
}

.icon.s {
  width: 1.125rem;
  height: 1.125rem;
}

.icon.l {
  width: 2rem;
  height: 2rem;
}

.icon >>> svg {
  display: block;
  width: 100%;
  height: 100%;
}
</style>
