<template>
  <span>{{ formattedValue }}</span>
</template>

<script lang="ts" setup>
import { computed, defineProps } from 'vue';

const props = defineProps<{ value: number }>();

const formattedValue = computed(() => {
  if (Number.isNaN(props.value)) {
    return '';
  }
  return new Intl.NumberFormat().format(props.value);
});
</script>
