<template>
  <div class="event-item-container">
    <a :class="`event-item ${layout || 'default'}${preview ? ' preview' : ''}`" :href="layout === 'search' || preview ? undefined : edit ? `/account/events/${event.id}` : `/events/${event.id}/${event.slug}`" @click="openEvent">
      <div class="event-item-header">
        <ImageUpload v-if="preview" :model-value="event.image" :placeholder="`${event.image ? 'Change' : 'Add'} cover`" @update:model-value="emit('image-upload', $event)" />
        <img v-else-if="event.image" :src="event.image" :alt="event.name" class="event-item-image">
        <div class="event-item-date" v-if="!hideDate && event.start_date">
          <span class="day">{{ format(event.start_date, 'd') }}</span>
          <span class="month">{{ format(event.start_date, 'MMM') }}</span>
          <span class="year">{{ format(event.start_date, 'yyyy') }}</span>
        </div>
        <div v-if="event.genres" class="event-item-tags">
          <span class="badge" v-for="genre in event.genres.slice(0, 2)" :key="genre">{{ genre }}</span>
        </div>
      </div>
      <div class="event-item-content">
        <div class="event-item-title">{{ event.name.trim() || 'Event name' }}</div>
        <div class="event-item-details">
          <div class="event-item-location">
            <SmartIcon name="pin" size="s" />
            {{ event.venue.name }}
          </div>
          <div class="event-item-time" v-if="event.start_date">
            <span class="material-icons">schedule</span>
            {{ format(event.start_date, 'h:mm aaaa') }}
          </div>
        </div>
        <div class="event-item-actions" v-if="layout === 'search'">
          <div class="event-item-buttons"></div>
          <button class="btn orange" :disabled="disableRequest" @click="$emit('request', event)">{{ disableRequest ? 'Request sent' : 'Send request' }}</button>
        </div>
        <div class="event-item-actions" v-else>
          <div class="event-item-buttons">
            <button class="icon-button like-button" :style="{ pointerEvents: preview || disableLike ? 'none' : undefined }" :disabled="loading" @click.stop.prevent="toggleLike">
              <span class="icon">
                <span class="material-icons filled" v-if="like || preview">favorite</span>
                <span class="material-icons" v-else>favorite_border</span>
              </span>
              <span class="text">{{ preview ? 137 : event.likes || 0 }}</span>
            </button>
            <button class="icon-button" @click.stop.prevent="!preview && openShare()">
              <span class="icon">
                <span class="material-icons">ios_share</span>
              </span>
              <span class="text">Share it</span>
            </button>
          </div>
          <div class="icon-tag">
            <span class="material-icons">group</span>
            <span class="text">{{ preview ? 89 : event.attendees || 0 }} are going</span>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { format } from 'date-fns';
import { client } from '@/api/api';
import SmartIcon from './SmartIcon.vue';
import ImageUpload from './ImageUpload.vue';

const props = defineProps<{ event: any, like?: any, hideDate?: boolean, disableLike?: boolean, disableRequest?: boolean, edit?: boolean, preview?: boolean, layout?: 'default' | 'search' }>();
const emit = defineEmits(['image-upload', 'request']);

const loading = ref(false);
const router = useRouter();
const route = useRoute();
const store = useStore();

const toggleLike = async () => {
  if (!store.state.user) {
    router.push(`/login?back=${route.fullPath}`);
    return;
  }
  if (loading.value || props.disableLike) {
    return;
  }
  loading.value = true;
  try {
    if (props.like) {
      await client.delete(`/events/${props.event.id}/likes`);
    } else {
      await client.post(`/events/${props.event.id}/likes`);
    }
  } catch (error) {
    console.warn(error);
  }
  loading.value = false;
};

const openShare = () => {
  window.dispatchEvent(new CustomEvent('share-dialog', {
    detail: { path: `/events/${props.event.id}/${props.event.slug}` },
  }));
};

const openEvent = (event: Event) => {
  if (!props.preview && props.layout !== 'search') {
    event.preventDefault();
    router.push(props.edit ? `/account/events/${props.event.id}` : `/events/${props.event.id}/${props.event.slug}`);
  }
};
</script>

<style scoped>
.event-item {
  display: block;
  transition: all 0.2s ease-out;
}

.event-item.search {
  cursor: default;
}

.event-item:hover {
  box-shadow: 0px 8px 16px 3px rgba(37, 48, 64, 0.23);
}

.event-item .event-item-header {
  position: relative;
  padding-top: 67%;
  overflow: hidden;
}

.event-item.preview .event-item-header {
  padding: 0;
}

.event-item .event-item-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-out;
}

.event-item:hover .event-item-image {
  transform: scale(1.05);
}

.event-item .event-item-tags {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 8px;
  bottom: 16px;
  right: 24px;
}

.event-item .event-item-date {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 64px;
  background: var(--jms-orange);
  padding: 16px 8px 6px;
  line-height: 0.85;
  text-align: center;
  text-transform: uppercase;
}

.event-item .event-item-date .day {
  font-size: 2rem;
  font-weight: bold;
}

.event-item .event-item-date .month {
  font-weight: bold;
}

.event-item .event-item-date .year {
  font-size: 0.75rem;
  margin-top: 12px;
}

.event-item .event-item-content {
  background: var(--background-light-opacity);
  padding: 24px;
}

.event-item .event-item-title {
  font-size: 1.5rem;
  font-weight: bold;
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--border-color);
}

.event-item .event-item-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 12px;
}

.event-item .event-item-location,
.event-item .event-item-time {
  display: flex;
  align-items: center;
  font-weight: 500;
  gap: 4px;
}

.event-item .material-icons {
  font-size: 1rem;
}

.event-item .like-button {
  font-variant-numeric: tabular-nums;
}

.event-item .material-icons.filled {
  color: var(--jms-red);
}

.event-item .event-item-actions {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 12px;
  margin-top: 24px;
}

.event-item .event-item-buttons {
  display: flex;
  align-items: center;
  gap: 8px 12px;
  flex-grow: 1;
}
</style>
