<template>
  <div class="input-container">
    <div :class="`image-container${getSource(modelValue) ? ' has-value' : ''}${disabled ? ' disabled' : ''}`">
      <img v-if="modelValue" class="image-preview" :src="getSource(modelValue)" alt="" />
      <button type="button" class="btn" @click.prevent.stop="openFile" :disabled="disabled">{{ placeholder || 'Select an image' }}</button>
    </div>
    <input ref="fileInputx" class="file-input" type="file" accept=".png,.jpg,.jpeg" @change="onUpload" :disabled="disabled" :required="required" />
  </div>
</template>

<script lang="ts" setup>
import { defineProps, defineEmits, ref } from 'vue';

defineProps<{ modelValue: File | string | null, required?: boolean, disabled?: boolean, placeholder?: string }>();
const emit = defineEmits(['update:modelValue']);

const fileInputx = ref<HTMLInputElement>();

const getSource = (value: string | File | null) => {
  if (typeof value === 'string') {
    return value;
  }
  if (value instanceof File) {
    return URL.createObjectURL(value);
  }
};

const openFile = () => {
  fileInputx.value?.click();
};

const onUpload = (event: any) => {
  const [file] = event.target.files;
  emit('update:modelValue', file || null);
};
</script>

<style scoped>
.input-container {
  position: relative;
}

.image-container {
  position: relative;
  display: flex;
  padding-top: 67%;
  background: var(--input-background-opacity);
}

.image-container.has-value {
  background: var(--background-black);
}

.image-container.disabled {
  opacity: 0.33;
}

.image-container .btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-container.has-value .btn {
  opacity: 0;
}

.image-container.has-value:hover .btn {
  opacity: 1;
}

.image-preview {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.2s ease-out;
}

.image-container.has-value:hover .image-preview {
  opacity: 0.46;
}

.file-input {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 1px;
  height: 1px;
}
</style>
