<template>
  <div :class="`app-container${hasTouch ? ' has-touch' : ' no-touch'}`" v-if="store.state.user !== undefined" @click="navOpened = false">
    <header ref="header" :class="`header${hideHeader ? ' hidden' : ''}`">
      <div class="wrapper">
        <router-link to="/">
          <img src="@/assets/jms-logo.svg" alt="Juicy Music Square" class="header-logo">
        </router-link>
        <nav :class="`header-nav${navOpened ? ' opened' : ''}`">
          <router-link class="header-nav-item" to="/">Home</router-link>
          <router-link class="header-nav-item" to="/events">Events</router-link>
          <router-link class="header-nav-item" to="/venues">Venues</router-link>
          <router-link class="header-nav-item" to="/artists">Artists</router-link>
          <router-link class="header-nav-item" to="/bands">Bands</router-link>
          <router-link class="header-nav-item" to="/news">News</router-link>
          <!-- <router-link class="header-nav-item" to="/podcast">Podcast</router-link> -->
          <router-link class="header-nav-item" to="/about">About</router-link>
        </nav>
        <div class="header-actions" v-if="store.state.user">
          <button :class="`icon notification-button${hasUnreadNotifications  ? ' unread' : ''}`" @click.stop="notificationsOpened = !notificationsOpened; accountNavOpened = false">
            <SmartIcon name="bell" size="xs" />
          </button>
          <div class="account-button-container">
            <button type="button" class="account-button" @click.stop="accountNavOpened = !accountNavOpened; notificationsOpened = false">
              <img v-if="store.state.user.image" :src="store.state.user.image" alt="" class="account-button-avatar">
              <div v-else class="account-button-avatar"></div>
              <div class="account-button-content">
                <div class="account-button-name">
                  <span>{{ store.state.user.first_name }} {{ store.state.user.last_name }}</span>
                  <SmartIcon name="chevron-down" size="s" />
                </div>
                <div class="account-button-type">{{ store.state.user.type }}</div>
              </div>
            </button>
            <div class="account-nav" v-if="accountNavOpened">
              <router-link class="account-nav-item" to="/account">
                <SmartIcon name="person-circle" size="xs" />
                <span>Profile</span>
              </router-link>
              <router-link v-if="store.state.user.type === 'artist' || store.state.user.type === 'venue'" class="account-nav-item" to="/account/requests">
                <SmartIcon name="request" size="xs" />
                <span>Requests</span>
              </router-link>
              <router-link v-if="store.state.user.type === 'artist'" class="account-nav-item" to="/account/bands">
                <SmartIcon name="users" size="xs" />
                <span>Bands</span>
              </router-link>
              <router-link v-if="store.state.user.type === 'artist' || store.state.user.type === 'venue'" class="account-nav-item" to="/account/events">
                <SmartIcon name="calendar" size="xs" />
                <span>Gigs & events</span>
              </router-link>
              <router-link v-if="store.state.user.type === 'artist' || store.state.user.type === 'venue'" class="account-nav-item" to="/account/billing">
                <SmartIcon name="currency" size="xs" />
                <span>Billing</span>
              </router-link>
              <a class="account-nav-item" @click="logOut">
                <SmartIcon name="logout" size="s" />
                <span>Sign out</span>
              </a>
            </div>
          </div>
          <button class="icon menu-button" @click.stop="navOpened = !navOpened">
            <span class="material-icons">menu</span>
          </button>
          <div class="notification-container" v-if="notificationsOpened">
            <NotificationList :notifications="store.state.notifications" :last-read="lastRead" :has-unread="hasUnreadNotifications" @mark-as-read="markAsRead" />
          </div>
        </div>
        <div class="header-actions" v-else>
          <router-link class="btn" to="/login">Sign in</router-link>
          <button class="icon menu-button" @click.stop="navOpened = !navOpened">
            <span class="material-icons">menu</span>
          </button>
        </div>
      </div>
    </header>
    <div id="butter">
      <router-view />
      <footer>
        <div class="wrapper">
          <div class="footer-container">
            <div class="col">
              <router-link to="/">
                <img src="@/assets/jms-logo.svg" alt="Juicy Music Square" class="footer-logo">
              </router-link>
            </div>
            <div class="col m">
              <div class="footer-header">Juicy Music Square</div>
              <router-link class="footer-item" to="/">Home</router-link>
              <router-link class="footer-item" to="/venues">Venues</router-link>
              <router-link class="footer-item" to="/artists">Artists</router-link>
              <router-link class="footer-item" to="/bands">Bands</router-link>
              <router-link class="footer-item" to="/news">News</router-link>
              <router-link class="footer-item" to="#" disabled>Podcast</router-link>
            </div>
            <div class="col m">
              <div class="footer-header">Leaderboards</div>
              <router-link class="footer-item" to="/leaderboard/venues">Top Venues</router-link>
              <router-link class="footer-item" to="/leaderboard/artists">Top Artists</router-link>
              <router-link class="footer-item" to="/leaderboard/bands">Top Bands</router-link>
            </div>
            <div class="col m">
              <div class="footer-header">Info & help</div>
              <router-link class="footer-item" to="/pricing">Pricing</router-link>
              <router-link class="footer-item" to="/about">About</router-link>
              <router-link class="footer-item" to="#" disabled>Contact</router-link>
              <router-link class="footer-item" to="#" disabled>Help</router-link>
              <router-link class="footer-item" to="/dev-log">Dev log</router-link>
            </div>
            <div class="col m">
              <div class="footer-header">Follow us</div>
              <a href="https://x.com/juicymusicsquare" target="_blank" rel="noopener noreferrer" class="footer-item">
                <SmartIcon name="x" size="xs" />
                @juicymusicsquare
              </a>
            </div>
            <div class="col x">
              <div class="footer-header">Contact us</div>
              <div class="form-success" v-if="contactSent">
                Thank you for reaching out to us!<br>
                We will get back to you shortly.
              </div>
              <form @submit.prevent="sendContact" v-else>
                <div class="field">
                  <input class="input" type="email" maxlength="255" placeholder="Your email..." v-model="contactEmail" required>
                </div>
                <div class="field">
                  <textarea class="input" placeholder="Write your message here..." maxlength="1500" v-model="contactMessage" required></textarea>
                </div>
                <button type="submit" class="btn dark" :disabled="loading || !contactEmail || !contactMessage">Send</button>
              </form>
            </div>
          </div>
          <div class="footer-bottom">
            <div class="signature">
              <div><b>Juicy Music Square</b> | version 1.0.0</div>
              <div>Made by <a href="https://blackpugstudio.com" target="_blank" rel="noopener noreferrer">Black Pug Studio</a> - Because we love music!</div>
            </div>
            <div class="copyright">© {{ currentYear }} - Black Pug Studio Ltd. - All rights reserved.</div>
          </div>
        </div>
      </footer>
    </div>
    <ShareDialog />
    <ReviewDialog />
    <RequestDialog />
  </div>
</template>

<script lang="ts" setup>
import { computed, nextTick, onMounted, ref, watch } from 'vue';
import { useStore } from 'vuex';
import SmartIcon from './components/SmartIcon.vue';
import { client } from './api/api';
import { logOut } from './api';
import ShareDialog from './components/ShareDialog.vue';
import ReviewDialog from './components/ReviewDialog.vue';
import RequestDialog from './components/RequestDialog.vue';
import NotificationList from './components/NotificationList.vue';

const store = useStore();

const _lastRead = window.localStorage.getItem('last-read');

const currentYear = ref(new Date().getFullYear());
const contactSent = ref(false);
const notificationsOpened = ref(false);
const loading = ref(false);
const header = ref();
const contactEmail = ref('');
const contactMessage = ref('');
const hideHeader = ref(false);
const navOpened = ref(false);
const butterInit = ref(false);
const accountNavOpened = ref(false);
const hasTouch = ref('ontouchstart' in document.documentElement);
const lastRead = ref(_lastRead ? new Date(_lastRead) : null);
const hasUnreadNotifications = computed(() => !!store.state.notifications.find((notification: any) => !lastRead.value || notification.created_at > lastRead.value));

const sendContact = async () => {
  if (loading.value) {
    return;
  }
  loading.value = true;
  try {
    await client.post('/contact', {
      email: contactEmail.value,
      message: contactMessage.value,
    });
    contactSent.value = true;
  } catch (error) {
    console.warn(error);
  }
  loading.value = false;
};

const markAsRead = () => {
  lastRead.value = new Date();
  window.localStorage.setItem('last-read', lastRead.value.toJSON());
};

const initButter = () => {
  if (!hasTouch.value && store.state.user !== undefined && !butterInit.value) {
    butterInit.value = true;
    nextTick(() => {
      (window as any).butter.init();
    });
  }
};

watch(store.state, () => {
  initButter();
});

onMounted(() => {
  let scrollValue = 0;
  window.addEventListener('click', () => {
    notificationsOpened.value = false;
    accountNavOpened.value = false;
  });
  window.addEventListener('scroll', () => {
    if (header.value) {
      hideHeader.value = window.scrollY > header.value.clientHeight && scrollValue < window.scrollY;
      scrollValue = window.scrollY;
    }
  });
  initButter();
});
</script>

<style>
:root {
  --text-color: #FFF;
  --text-highlight-color: #97b3ef;
  --text-color-light: rgba(255, 255, 255, 0.69);
  --tab-highlight-color: rgba(151, 179, 239, 0.16);
  --border-color: rgba(255, 255, 255, 0.46);
  --border-color-light: rgba(255, 255, 255, 0.23);
  --background-light: #4e5e7f;
  --background-dark: #2C3E50;
  --background-darker: #253040;
  --background-black: #1F1D2C;
  --input-background-opacity: rgba(78, 94, 127, 0.69);
  --background-highlight-opacity: rgba(151, 179, 239, 0.69);
  --border-highlight-opacity: rgba(151, 179, 239, 0.46);
  --background-light-opacity: rgba(78, 94, 127, 0.46);
  --background-lighter-opacity: rgba(78, 94, 127, 0.16);
  --background-dark-opacity: rgba(44, 62, 80, 0.69);
  --background-darker-opacity: rgba(37, 48, 64, 0.46);
  --background-gray-opacity: rgba(44, 62, 80, 0.46);
  --background-black-opacity: rgba(31, 29, 44, 0.33);
  --box-shadow-opacity: rgba(78, 94, 127, 0.23);
  --box-shadow-dark-opacity: rgba(44, 62, 80, 0.23);
  --button-hover-orange: #EC7B0B;
  --button-pressed-orange: #F79C40;
  --button-hover-red: #D7214E;
  --button-pressed-red: #FF4E7B;
  --icon-button-pressed: rgba(108, 127, 145, 0.46);
  --jms-orange: #E7892B;
  --jms-yellow: #FFCC00;
  --jms-red: #FF3366;
  --jms-green: #12B999;
  --jms-light-gray: #DEE4E5;
}

html {
  font-size: 16px;
}

* {
  box-sizing: border-box;
  outline: none;
}

button {
  border: none;
  background: none;
  color: inherit;
  font: inherit;
  cursor: pointer;
  text-align: left;
  -webkit-appearance: none;
  appearance: none;
}

h1, h2, h3, p {
  margin: 0;
  font-size: inherit;
}

a {
  cursor: pointer;
  color: inherit;
  text-decoration: none;
}

img {
  display: block;
}

label {
  display: block;
  margin-bottom: 4px;
  font-weight: 500;
  font-size: 0.875rem;
  text-align: left;
}

.input,
.vue-tel-input .vti__input {
  display: block;
  width: 100%;
  height: 42px;
  background: var(--input-background-opacity);
  border-radius: 0;
  border: none;
  color: #fff;
  font: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 0 16px;
  appearance: none;
}

select.input {
  background: var(--input-background-opacity) url(./assets/ic-chevron-down.svg) no-repeat calc(100% - 12px) center / 20px auto;
}

form .vue-tel-input,
form .vue-tel-input:focus-within {
  gap: 8px;
  border: none;
  box-shadow: none;
}

.vue-tel-input.disabled {
  opacity: 0.33;
}

.vue-tel-input .vti__dropdown,
.vue-tel-input .vti__dropdown.open,
.vue-tel-input .vti__dropdown:hover {
  background: var(--input-background-opacity);
}

.vue-tel-input.invalid .vti__input,
.vue-tel-input.invalid .vti__dropdown,
.vue-tel-input.invalid .vti__dropdown.open,
.vue-tel-input.invalid .vti__dropdown:hover {
  border-bottom: 1px solid var(--jms-red) !important;
}

.vue-tel-input .vti__dropdown-arrow {
  color: var(--text-color);
}

.vue-tel-input .vti__dropdown-list {
  background: var(--background-light);
  border-color: var(--background-darker);
}

.vue-tel-input .vti__dropdown-item.highlighted {
  background: var(--border-color-light);
}

.input[disabled] {
  cursor: default;
  opacity: 0.33;
}

.input::placeholder {
  opacity: 1;
  color: var(--text-color-light);
}

textarea.input {
  padding: 16px;
  resize: none;
  height: 196px;
  line-height: 1.6;
}

.mx-datepicker-main.mx-datepicker-popup,
.wsip-datepicker.mx-datepicker,
.wsip-datepicker input {
  font-family: 'all-round-gothic';
}

.wsip-datepicker.disabled {
  opacity: 0.5;
}

.wsip-datepicker.mx-datepicker {
  width: 100%;
}

.mx-datepicker-main .mx-calendar-content .cell.disabled {
  background: none;
  opacity: 0.33;
}

.wsip-datepicker input {
  width: 100%;
  height: 42px;
  background: var(--input-background-opacity);
  border-radius: 0;
  color: #fff;
  border: none;
  padding: 0 16px;
}

.mx-input:disabled, .mx-input.disabled {
  opacity: 0.33;
  color: #fff;
  cursor: default;
}

.mx-datepicker-main.mx-datepicker-popup,
.mx-datepicker-main .mx-time {
  border-radius: 0;
  background: var(--background-dark);
  color: #fff;
  border: none;
}

.mx-datepicker-main .mx-btn,
.mx-datepicker-main .mx-icon-calendar,
.mx-datepicker-main .mx-icon-clear,
.mx-datepicker .mx-icon-calendar,
.mx-datepicker .mx-icon-clear,
.mx-datepicker .mx-icon-clear:hover {
  color: rgba(255, 255, 255, 0.69);
}

.mx-datepicker .mx-icon-calendar, .mx-datepicker .mx-icon-clear {
  right: 16px;
}

.mx-datepicker-main .mx-table-date .today,
.mx-datepicker-main .mx-time-column .mx-time-item.active {
  color: #FC0;
  font-weight: 700;
}

.mx-datepicker-main .mx-calendar-content .cell.active {
  background: #FC0;
  color: #393652;
  font-weight: 700;
}

form .fieldgroup {
  display: flex;
  justify-content: stretch;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 16px 12px;
}

form .fieldgroup .field {
  width: 100%;
}

form .fieldgroup .field.m {
  width: calc(50% - 6px);
}

form .field {
  margin-bottom: 16px;
}

form .field .field-note {
  font-size: 0.75rem;
  font-weight: 500;
  color: var(--jms-light-gray);
  margin-top: 4px;
}

form .actions {
  margin-top: 24px;
}

form .actions {
  display: flex;
  align-items: center;
  gap: 8px;
  justify-content: center;
  flex-wrap: wrap;
}

.wrapper {
  width: 1440px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 48px;
}

.wrapper.small {
  width: 1056px;
}

.view {
  min-height: calc(100vh - 591px);
  padding: 1px 0;
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 42px;
  padding: 0 24px;
  border: 1px solid #fff;
  border-radius: 0;
  font-size: 1rem;
  font-weight: 500;
  transition: all 0.2s ease-out;
  white-space: nowrap;
}

.btn:not([disabled]):hover {
  background: var(--jms-orange);
  border-color: var(--jms-orange);
}

.btn:not([disabled]):active {
  background: var(--button-pressed-orange);
  border-color: var(--button-pressed-orange);
}

.btn.red {
  background: var(--jms-red);
  border-color: var(--jms-red);
  font-weight: 500;
}

.btn.red:not([disabled]):hover {
  background: var(--button-hover-red);
  border-color: var(--button-hover-red);
}

.btn.red:not([disabled]):active {
  background: var(--button-pressed-red);
  border-color: var(--button-pressed-red);
}

.btn.orange {
  background: var(--jms-orange);
  border-color: var(--jms-orange);
  font-weight: 500;
}

.btn.orange:not([disabled]):hover {
  background: var(--button-hover-orange);
  border-color: var(--button-hover-orange);
}

.btn.orange:not([disabled]):active {
  background: var(--button-pressed-orange);
  border-color: var(--button-pressed-orange);
}

.btn.highlight {
  color: var(--text-highlight-color);
  border-color: var(--text-highlight-color);
  font-weight: 500;
}

.btn.highlight:not([disabled]):hover {
  background: var(--text-highlight-color);
  border-color: var(--text-highlight-color);
  color: var(--text-color);
}

.btn.highlight:not([disabled]):active {
  background: var(--text-highlight-color);
  border-color: var(--text-highlight-color);
  color: var(--text-color);
}

.btn.dark {
  background: var(--background-dark);
  border-color: var(--background-dark);
}

.btn.plain {
  border-color: transparent;
}

.btn.plain:not([disabled]):hover {
  border-color: transparent;
  background: var(--border-color-light);
}

.btn.plain:not([disabled]):active {
  border-color: transparent;
  background: var(--border-color);
}

.badge {
  padding: 0 12px;
  line-height: 24px;
  font-size: 0.875rem;
  font-weight: 600;
  text-transform: capitalize;
  white-space: nowrap;
  background: var(--jms-green);
}

.icon-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  height: 32px;
  background: var(--background-gray-opacity);
  line-height: 1;
  padding: 0;
}

.btn[disabled],
.icon-button[disabled] {
  opacity: 0.33;
  cursor: default;
}

.icon-button:not([disabled]):active {
  background: var(--icon-button-pressed);
}

.icon-button:not([disabled])::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 32px;
  width: 0;
  transition: width 0.2s ease-out;
  background: var(--background-dark);
}

.icon-button:not([disabled]):hover::before {
  width: calc(100% - 32px);
}

.icon-button.plain {
  background: var(--background-light-opacity);
}

.icon-button .icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background: var(--background-dark);
}

.icon-button:not([disabled]):active .icon {
  background: transparent;
}

.icon-button:not([disabled]):active::before {
  background: var(--icon-button-pressed);
}

.icon-button.plain .icon {
  background: var(--background-light);
}

.icon-button.plain:not([disabled])::before {
  background: var(--background-light);
}

.icon-button .icon .material-icons {
  font-size: 1rem;
  transition: transform 0.2s ease-out;
  will-change: transform;
}

.icon-button:not([disabled]):hover .material-icons {
  transform: scale(1.1);
}

.icon-button .text {
  position: relative;
  padding: 0 12px;
  font-size: 0.875rem;
  font-weight: 500;
}

.account-button-container {
  position: relative;
}

.account-nav {
  position: absolute;
  right: 0;
  top: 100%;
  width: 200px;
  margin-top: 12px;
  padding: 8px 0;
  z-index: 3;
  background: var(--background-dark);
}

.header.hidden .account-nav {
  display: none;
}

.account-nav .account-nav-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 16px;
  font-weight: 500;
}

.account-nav .account-nav-item:hover,
.account-nav .account-nav-item.router-link-exact-active {
  background: var(--tab-highlight-color);
}

.account-nav .account-nav-item.router-link-exact-active {
  border-right: 2px solid var(--text-highlight-color);
}

.account-button {
  display: flex;
  align-items: center;
  gap: 12px;
  text-transform: capitalize;
  font-size: 1rem;
}

.account-button .account-button-name {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 600;
  margin-bottom: 4px;
}

.account-button .account-button-type {
  font-size: 0.875rem;
}

.account-button .account-button-avatar {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  object-fit: cover;
  flex-shrink: 0;
  background: var(--background-darker);
}

.icon-tag {
  display: inline-flex;
  align-items: center;
  gap: 8px;
  font-size: 0.875rem;
  font-weight: 500;
  justify-self: flex-end;
  white-space: nowrap;
}

.icon-tag .material-icons {
  font-size: 1rem;
}

.form-container {
  width: 440px;
  max-width: 100%;
  padding: 40px 24px 24px 24px;
  background: var(--background-darker-opacity);
  margin: 80px auto;
}

.form-container a:not(.btn) {
  color: var(--text-highlight-color);
}

.form-container .post-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
}

.form-container .post-actions .option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.form-container .form-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 12px;
}

.form-container .form-subtitle {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 24px;
}

.form-container .actions .btn,
.form-container .form-buttons .btn {
  width: 100%;
}

.form-container .form-buttons {
  margin-top: 40px;
}

.form-container .line {
  text-align: center;
  margin-top: 16px;
}

.form-container .form-buttons::before {
  content: '';
  display: block;
  width: 96px;
  height: 1px;
  margin: 0 auto 16px;
  background: var(--text-color);
}

.form-container .form-buttons .btn:not(:first-child) {
  margin-top: 8px;
}

form .error,
form .success-message,
.form-container .error {
  background: var(--jms-red);
  padding: 12px 16px;
  font-size: 0.875rem;
  font-weight: 500;
  text-align: center;
}

form .success-message {
  background: var(--jms-orange);
}

.form-footer {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  margin-top: 80px;
}

.form-footer .links {
  display: flex;
  gap: 16px;
  flex-grow: 1;
}

.form-footer .copyright {
  opacity: 0.46;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px;
  z-index: 100;
  background: var(--background-dark-opacity);
}

.overlay .dialog {
  background: var(--background-darker);
  padding: 64px 48px;
  width: 512px;
  max-width: 100%;
}

.overlay .dialog-title {
  position: relative;
  font-size: 2rem;
  font-weight: bold;
  color: var(--jms-orange);
}

.overlay .dialog .field {
  margin-top: 24px;
}

.overlay .dialog-content {
  margin: 32px 0;
}

.overlay .dialog-message {
  margin-top: 24px;
  margin-bottom: -24px;
  color: rgba(255, 255, 255, 0.69);
}

.overlay .dialog-actions {
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 8px;
}

.overlay .dialog-actions .btn {
  width: 100%;
}

#app {
  position: relative;
  font: 400 1rem/normal all-round-gothic, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;
}

.app-container.no-touch #butter {
  top: 0;
  padding-top: 112px;
}

.app-container.has-touch {
  padding-top: 112px;
}

.view table {
  width: 100%;
  border-collapse: collapse;
}

.view table th,
.view table td {
  padding: 16px;
}

.view table th {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: left;
  border-bottom: 1px solid var(--background-highlight-opacity);
}

.view table td {
  font-size: 1.125rem;
  font-weight: 500;
  padding-bottom: 18px;
}

.view table td .note {
  font-style: italic;
  opacity: 0.46;
  margin-left: 4px;
}

.view table tr:not(:first-child) {
  background: url(./assets/jms-bg-dashed.svg) repeat-x bottom left/auto;
}

.view table tr:not(:first-child):hover {
  background-color: var(--tab-highlight-color);
}

.view table .center {
  text-align: center;
}

.view table .right {
  text-align: right;
}

.loader,
.loader:after {
  border-radius: 50%;
  width: 10em;
  height: 10em;
}

.loader {
  font-size: 2px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.1em solid rgba(242, 114, 114, 0.2);
  border-right: 1.1em solid rgba(242, 114, 114, 0.2);
  border-bottom: 1.1em solid rgba(242, 114, 114, 0.2);
  border-left: 1.1em solid #FC0;
  transform: translateZ(0);
  animation: load8 1s infinite linear;
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

header {
  position: fixed;
  padding: 32px 48px;
  top: 0;
  left: 0;
  right: 0;
  background: var(--background-light);
  z-index: 100;
  transition: transform 0.2s ease-out;
}

header.hidden {
  transform: translateY(-100%);
}

header button.menu-button {
  display: none;
}

header .wrapper {
  display: flex;
  align-items: center;
  gap: 32px;
}

header .header-nav {
  display: flex;
  align-items: center;
  gap: 32px;
  flex-grow: 1;
  font-weight: 500
}

header .header-logo {
  width: 48px;
}

header .notification-container {
  position: absolute;
  width: 320px;
  top: 100%;
  right: 0;
  margin-top: 12px;
  background: var(--background-dark);
  z-index: 2;
}

header .notification-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 42px;
  padding: 0 12px;
  border: 1px solid var(--border-color-light);
  border-radius: 50%;
}

header .notification-button.unread::after {
  content: '';
  position: absolute;
  width: 8px;
  height: 8px;
  top: 8px;
  right: 9px;
  border-radius: 50%;
  background: var(--jms-orange);
}

header .header-actions {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

section:not(:first-child) {
  margin-top: 64px;
}

.section-title {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  padding: 12px 16px;
  white-space: nowrap;
}

.section-title:not(.icon):not(.with-image)::before {
  content: '';
  position: absolute;
  width: 64px;
  height: 64px;
  top: 2px;
  left: 0;
  background: var(--background-light);
}

.section-title.with-image {
  padding: 0;
}

.section-title.icon {
  padding: 0;
}

.section-title.red::before {
  background-color: var(--jms-red) !important;
}

.section-title h2 {
  position: relative;
  font-size: 2.25rem;
  margin-right: 24px;
}

.section-title .section-icon {
  width: 64px;
  margin-right: 16px;
}

.section-title.with-image .section-title-image {
  margin-right: 16px;
  margin-left: -16px;
}

.section-title .section-title-note {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 12px;
}

.section-title .section-title-note::before {
  content: '';
  display: block;
  width: 64px;
  height: 1px;
  background: var(--background-light);
}

.section-actions {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  gap: 16px;
  flex-wrap: wrap;
}

footer {
  background: var(--background-light);
  padding: 48px 0 32px;
  margin-top: 64px;
}

footer .footer-container {
  display: flex;
  padding: 0 32px 40px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.23);
  margin-bottom: 24px;
}

footer .footer-header {
  font-size: 0.875rem;
  font-weight: 600;
  margin-bottom: 16px;
  color: var(--text-highlight-color);
}

footer .col.m {
  width: 13.7%;
}

footer .col.x {
  flex-grow: 1;
  padding-left: 24px;
}

footer .footer-item {
  display: block;
  margin-bottom: 16px;
  font-size: 0.875rem;
  font-weight: 500;
  transition: color 0.2s ease-out;
}

footer .footer-item .icon {
  vertical-align: bottom;
}

footer a.footer-item:hover {
  color: var(--jms-orange);
}

footer .footer-item[disabled] {
  pointer-events: none;
  color: rgba(255, 255, 255, 0.46);
}

footer .footer-item[disabled]::after {
  content: 'Coming soon';
  display: inline;
  font-size: 12px;
  margin-left: 12px;
  font-style: italic;
}

footer .footer-logo {
  display: block;
  width: 107px;
  margin-top: 8px;
  margin-right: 48px;
}

footer .field {
  margin-bottom: 12px;
}

footer form .btn {
  width: 100%;
  height: 48px;
  margin-top: 12px;
  font-weight: 700;
}

footer form .btn[disabled] {
  opacity: 1;
  color: rgba(255, 255, 255, 0.46);
}

footer .input {
  background: none;
  border: 1px solid rgba(255, 255, 255, 0.23);
  font-weight: normal;
  height: 32px;
  font-size: 14px;
}

footer textarea.input {
  height: 64px;
}

footer .footer-bottom {
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  font-size: 14px;
}

footer .footer-bottom a {
  font-weight: 600;
  text-decoration: underline;
}

footer .footer-bottom .legal {
  font-size: 12px;
  opacity: 0.46;
  margin-top: 24px;
  font-style: italic;
}

@media screen and (max-width: 1024px) {
  header {
    padding: 16px 32px;
  }
  header .wrapper {
    justify-content: space-between;
    padding: 0;
  }
  header button.menu-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin-left: 8px;
  }
  header button.icon .material-icons {
    font-size: 2rem;
  }
  header .header-nav {
    position: absolute;
    align-items: flex-start;
    width: 200px;
    flex-direction: column;
    top: 100%;
    right: 32px;
    margin-top: 12px;
    background: var(--background-light);
    padding: 12px 0;
    gap: 0;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-out;
  }
  header .header-nav.opened {
    opacity: 1;
    visibility: visible;
  }
  header .header-nav .header-nav-item {
    padding: 8px 24px;
    font-size: 1.125rem;
  }
  header .header-actions {
    gap: 16px;
  }
  .app-container.no-touch #butter {
    padding-top: 80px;
  }
  .app-container.has-touch {
    padding-top: 80px;
  }
  .section-title {
    flex-wrap: wrap;
    white-space: initial;
  }
  .section-title br {
    display: none;
  }
  .section-title h2 {
    width: 100%;
    margin-bottom: 16px;
    margin-top: 12px;
  }
  .wrapper {
    padding: 0 32px;
  }
  footer .footer-container {
    flex-wrap: wrap;
    padding: 0 0 40px;
    gap: 12px;
  }
  footer .footer-logo {
    width: 128px;
    margin: 0;
  }
  footer .col,
  footer .col.m {
    margin-top: 16px;
    width: calc(50% - 6px);
  }
  footer .col.m:first-child {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    margin-bottom: 24px;
  }
  footer .footer-bottom {
    display: block;
    text-align: center;
  }
  footer .copyright {
    margin-top: 24px;
  }
  .section-title .section-title-note::before {
    width: 48px;
  }
}

@media screen and (max-width: 800px) {
  table th,
  table td {
    padding-left: 12px;
    padding-right: 12px;
  }
  table th {
    font-size: 1.125rem;
  }
  table td {
    font-size: 1rem;
  }
}

@media screen and (max-width: 640px) {
  .account-button .account-button-content {
    display: none;
  }
  header .header-actions {
    gap: 12px;
  }
  .section-title .section-title-note {
    gap: 4px;
  }
  .section-title .section-title-note::before {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  table th,
  table td {
    padding-left: 8px;
    padding-right: 8px;
  }
  .wrapper {
    padding: 0 24px;
  }
  .section-title h2 {
    font-size: 2rem;
  }
  table th, table td {
    padding-left: 8px;
    padding-right: 8px;
  }
  table th:first-child, table td:first-child {
    padding-left: 0;
  }
  table th:last-child, table td:last-child {
    padding-right: 0;
  }
  table th {
    font-size: 1rem;
  }
  table td {
    font-size: 0.875rem;
  }
  .overlay {
    padding: 24px;
  }
  .overlay .dialog {
    padding: 48px 24px;
  }
  .form-container {
    margin: 48px 0 0;
  }
}

@media screen and (max-width: 420px) {
  footer .footer-logo {
    margin: 0 auto 48px;
  }
  footer .col,
  footer .col.m,
  footer .col.x {
    width: 100%;
    padding: 0;
  }
}
</style>
