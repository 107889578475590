<template>
  <div class="overlay" v-if="show" @click="show = loading">
    <div v-if="request?.invitation && request.invitation.status === 'confirmed'" class="dialog" @click.stop>
      <div class="dialog-title">Send a payment</div>
      <div v-if="request.invitation.payment" class="dialog-content">
        <div class="request-success">
          <div class="request-success-icon">
            <span class="material-icons">done</span>
          </div>
          <div class="request-success-message">Your payment was sent successfully!</div>
        </div>
      </div>
      <div v-else-if="request.invitation.event.start_date > new Date()" class="dialog-content">
        <div class="request-success">
          <div class="request-success-icon">
            <span class="material-icons">schedule</span>
          </div>
          <div class="request-success-message">You cannot pay the artist as your event has not started yet.</div>
        </div>
      </div>
      <div v-else class="dialog-content">
        <StripeForm @payment="sendPayment" :button-text="`Pay ${store.state.currencies[request.invitation.offer.currency]}${request.invitation.offer.price}`" :disabled="loading" />
        <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
      </div>
      <div class="dialog-actions">
        <button type="button" class="btn" :disabled="loading" @click="show = false">Close</button>
      </div>
    </div>
    <form v-else-if="request" class="dialog" @click.stop @submit.prevent="sendInvitation">
      <div class="dialog-title">{{ request.invitation ? 'Negotiate' : 'Send' }} a request</div>
      <div class="dialog-content" v-if="sent">
        <div class="request-success">
          <div class="request-success-icon">
            <span class="material-icons">done</span>
          </div>
          <div class="request-success-message">Your request was sent successfully!</div>
        </div>
      </div>
      <div class="dialog-content" v-else>
        <div class="field">
          <div class="current-offer" v-if="request.invitation">
            <div class="current-offer-price"><b>{{ request.invitation.sent_by === 'artist' ? request.invitation.artist.name : request.invitation.venue.name }}</b> proposed a performance fee of <b>{{ store.state.currencies[request.invitation.offer.currency] }}{{ request.invitation.offer.price }}</b>.</div>
            <div class="current-offer-content" v-if="request.invitation.offer.message">
              <img :src="request.invitation.sent_by === 'artist' ? request.invitation.artist.avatar : request.invitation.venue.avatar" alt="" class="current-offer-avatar">
              <div class="current-offer-message">
                <div class="current-offer-username">{{ request.invitation.sent_by === 'artist' ? request.invitation.artist.name : request.invitation.venue.name }}</div>
                <div class="current-offer-message-text">{{ request.invitation.offer.message }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="field">
          <label for="price">Proposed performance fee</label>
          <input id="price" name="price" v-model="requestForm.price" type="text" class="input" maxlength="10" :disabled="loading" @input="validatePrice" required>
        </div>
        <div class="field">
          <label for="message">Message (optional)</label>
          <textarea id="message" name="message" v-model="requestForm.message" class="input" maxlength="1000" :disabled="loading"></textarea>
        </div>
        <div class="field inline">
          <CheckBox size="l" label="Use JMS Payments" v-model="requestForm.use_payments" :disabled="!request.usePayments" />
          <TooltipIcon v-if="!request.usePayments" message="This artist has not registered to use JMS Payments." />
        </div>
        <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
      </div>
      <div class="dialog-actions" v-if="sent">
        <button type="button" class="btn" @click="show = false">Close</button>
      </div>
      <div class="dialog-actions" v-else>
        <button type="button" class="btn" :disabled="loading" @click="show = false">Cancel</button>
        <button type="submit" class="btn orange" :disabled="loading">{{ request.invitation ? 'Update' : 'Send' }} request</button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { getDoc, doc } from 'firebase/firestore';
import { client } from '@/api/api';
import { db } from '@/api';
import CheckBox from '@/components/CheckBox.vue';
import TooltipIcon from '@/components/TooltipIcon.vue';
import StripeForm from '@/components/StripeForm.vue';

const store = useStore();

const request = ref<{ target: string, id: string, usePayments: boolean, eventId?: string, invitation?: any, status?: string } | null>(null);
const show = ref(false);
const loading = ref(false);
const sent = ref(true);
const errorMessage = ref('');
const requestForm = ref({
  price: '',
  message: '',
  use_payments: false,
});

const validatePrice = (event: any) => {
  const parts = event.target.value.replace(/^\.|[^.0-9]/g, '').split('.').slice(0, 2);
  if (parts[1]) {
    parts[1] = parts[1].substring(0, 2);
  }
  event.target.value = parts.join('.');
};

const sendPayment = async ({ paymentMethod, discountCode }: { paymentMethod: string, discountCode: string }) => {
  const invitation = request.value?.invitation;
  if (loading.value || !request.value || !invitation) {
    return;
  }
  loading.value = true;
  try {
    const updatedInvitation = await client.post(`/events/${invitation.event.id}/invitations/${invitation.id}/payments`, {
      payment_method: paymentMethod,
      discount_code: discountCode,
    });
    request.value = {
      ...request.value,
      invitation: updatedInvitation,
    };
  } catch (error: any) {
    console.warn(error);
    errorMessage.value = error.message;
  }
  loading.value = false;
};

const sendInvitation = async () => {
  if (loading.value || !request.value) {
    return;
  }
  loading.value = true;
  errorMessage.value = '';
  try {
    const { target, id, eventId, invitation, status } = request.value;
    const { price, message, use_payments } = requestForm.value;
    await client.post(`/${target}s/${id}/invitations${invitation ? `/${invitation.id}` : ''}`, {
      event_id: eventId,
      price: (+price).toFixed(2),
      message,
      status,
      use_payments,
    });
    sent.value = true;
  } catch (error: any) {
    console.warn(error.message);
    errorMessage.value = error.message;
  }
  loading.value = false;
};

const getArtistAcceptPayment = async (artistId: string) => {
  loading.value = true;
  const query = await getDoc(doc(db, `artists/${artistId}`));
  const data = query.data();
  if (data && request.value) {
    request.value = {
      ...request.value,
      usePayments: data.accept_payments || false,
    };
    requestForm.value = {
      ...requestForm.value,
      use_payments: data.accept_payments || false,
    };
  }
  loading.value = false;
};

window.addEventListener('request-dialog', (event: any) => {
  request.value = event.detail || null;
  if (!request.value) {
    return;
  }
  const { usePayments, invitation } = request.value;
  requestForm.value = {
    price: '',
    use_payments: usePayments || false,
    message: request.value
      ?.invitation
      ?.offer_history
      ?.filter((offer: any) => request.value?.target && offer.sent_by === (request.value.target === 'venue' ? 'venue' : 'artist'))
      .pop()
      ?.message || '',
  };
  errorMessage.value = '';
  sent.value = false;
  show.value = true;
  if (typeof usePayments !== 'boolean' && invitation) {
    getArtistAcceptPayment(invitation.artist.owner_id);
  }
});
</script>

<style scoped>
.dialog-title {
  text-align: center;
}

.request-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.request-success-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--jms-orange);
}

.request-success-icon .material-icons {
  font-size: 1.5rem;
}

.request-success-message {
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
}

.current-offer {
  background: var(--background-light);
  padding: 16px 24px;
}

.current-offer .current-offer-price {
  font-size: 1.125rem;
}

.current-offer .current-offer-content {
  margin-top: 12px;
  padding-top: 12px;
  border-top: 1px solid var(--border-color-light);
}

.current-offer .current-offer-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: var(--background-dark);
}

.current-offer .current-offer-content {
  display: flex;
  gap: 12px;
  font-size: 0.875rem;
}

.current-offer .current-offer-username {
  font-weight: 600;
}

.field.inline {
  display: flex;
  align-items: center;
}
</style>
