<template>
  <div class="overlay" v-if="reviewForm && show" @click="show = loading">
    <form class="dialog" @click.stop @submit.prevent="submitReview">
      <div class="dialog-title">{{ reviewForm.reviewId ? `${reviewForm.toDelete ? 'Delete' : 'Update'} my review` : 'Post a review' }}</div>
      <div class="dialog-content" v-if="sent">
        <div class="request-success">
          <div class="request-success-icon">
            <span class="material-icons">done</span>
          </div>
          <div class="request-success-message">Your review was {{ reviewForm.reviewId ? reviewForm.toDelete ? 'deleted' : 'updated' : 'submitted' }} successfully!</div>
        </div>
      </div>
      <div class="dialog-content" v-else-if="reviewForm.toDelete">
        <div class="dialog-message">Are you sure you want to delete your review?</div>
      </div>
      <div class="dialog-content" v-else>
        <div class="field">
          <label for="price">Your score</label>
          <div class="rating">
            <button v-for="index in 5" :key="index" :disabled="loading" type="button" :class="`rating-button${index <= reviewForm.rating ? ' active' : ''}`" @click="reviewForm.rating = index">
              <div class="material-icons">star</div>
            </button>
          </div>
        </div>
        <div class="field">
          <label for="message">Your review</label>
          <textarea id="message" name="message" v-model="reviewForm.message" class="input" maxlength="1000" :disabled="loading"></textarea>
        </div>
        <div class="error" v-if="errorMessage">{{ errorMessage }}</div>
      </div>
      <div class="dialog-actions" v-if="sent">
        <button type="button" class="btn" @click="show = false">Close</button>
      </div>
      <div class="dialog-actions" v-else>
        <button type="button" class="btn" :disabled="loading" @click="show = false">Cancel</button>
        <button type="submit" class="btn orange" :disabled="loading">{{ reviewForm.reviewId ? reviewForm.toDelete ? 'Confirm' : 'Update review' : 'Submit review' }}</button>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { client } from '@/api/api';

const show = ref(false);
const loading = ref(false);
const sent = ref(true);
const errorMessage = ref('');
const reviewForm = ref();

const submitReview = async () => {
  if (loading.value) {
    return;
  }
  loading.value = true;
  errorMessage.value = '';
  try {
    const { rating, message, targetId, targetType, reviewId, toDelete } = reviewForm.value;
    if (toDelete) {
      await client.delete(`/${targetType}s/${targetId}/reviews/${reviewId}`);
    } else {
      await client.post(`/${targetType}s/${targetId}/reviews${reviewId ? `/${reviewId}` : ''}`, {
        score: rating,
        message,
      });
    }
    sent.value = true;
  } catch (error: any) {
    console.warn(error.message);
    errorMessage.value = error.message;
  }
  loading.value = false;
};

window.addEventListener('review-dialog', (event: any) => {
  if (!event.detail) {
    return;
  }
  const { targetId, targetType, rating = 0, message = '', reviewId = null, toDelete = false } = event.detail;
  reviewForm.value = {
    rating,
    message,
    targetId,
    targetType,
    reviewId,
    toDelete,
  };
  errorMessage.value = '';
  sent.value = false;
  show.value = true;
});
</script>

<style scoped>
.dialog-title {
  text-align: center;
}

.dialog .dialog-message {
  font-size: 1.125rem;
  text-align: center;
  margin-bottom: 24px;
}

.request-success {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;
}

.request-success-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: var(--jms-orange);
}

.request-success-icon .material-icons {
  font-size: 1.5rem;
}

.request-success-message {
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
}

.rating-button {
  opacity: 0.69;
}

.rating-button.active {
  opacity: 1;
  color: var(--jms-orange);
}

.rating-button[disabled],
.rating-button.active[disabled] {
  opacity: 0.33;
}
</style>
