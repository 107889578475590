<template>
  <div class="view home">
    <div v-if="featuredEvents.length" class="swiper" ref="featuredEventsEl">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="event in featuredEvents.length > 1 ? [...featuredEvents, ...featuredEvents, ...featuredEvents] : featuredEvents" :key="event.slug">
          <router-link :to="`/events/${event.id}/${event.slug}`" class="featured-event">
            <img :src="event.image" :alt="event.name" class="featured-event-image">
            <div class="featured-event-detail">
              <div class="featured-event-date">
                <div class="day">{{ format(event.start_date, 'd') }}</div>
                <div class="month">{{ format(event.start_date, 'MMM') }}</div>
                <div class="year">{{ format(event.start_date, 'yyyy') }}</div>
              </div>
              <div class="featured-event-container">
                <div class="featured-event-title-container">
                  <div class="featured-event-title">{{ event.name }}</div>
                  <div class="featured-event-tags">
                    <span class="badge" v-for="genre in event.genres.slice(0, 2)" :key="genre">{{ genre }}</span>
                  </div>
                </div>
                <div class="featured-event-info">
                  <div class="featured-event-location">
                    <SmartIcon name="pin" size="s" />
                    {{ event.venue.name }}
                  </div>
                  <div class="featured-event-time">
                    <span class="material-icons">schedule</span>
                    {{ format(event.start_date, 'h:mm aaaa') }}
                  </div>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="slider-pagination">
        <button
          :class="`slider-bullet${featuredEventsIndex % featuredEvents.length === index ? ' active' : ''}`"
          v-for="(event, index) in featuredEvents"
          :key="`bullet-${event.id}`"
          @click="featuredEventsSwiper.slideTo(index + featuredEvents.length - 1)"
        ></button>
      </div>
    </div>
    <section v-if="currentEvents.length" class="current-events">
      <div class="wrapper">
        <div class="section-title with-image">
          <img src="@/assets/jms-ic-today.png" alt="" class="section-title-image">
          <h2>What's happening today!</h2>
          <div class="section-title-line">Our editors picks for you today!</div>
          <div class="section-title-note">{{ format(currentDate, 'EEE, d LLL, yyyy') }}</div>
        </div>
        <div class="swiper" ref="currentEventsEl">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="event in currentEvents" :key="event.slug">
              <EventItem :event="event" hide-date :like="likes.find((like) => like.target.id === event.id)" />
            </div>
          </div>
          <div class="swiper-progress">
            <div class="swiper-progress-bar"></div>
          </div>
        </div>
        <div class="section-actions">
          <router-link class="btn" to="/events?date=today">View all events & gigs today</router-link>
        </div>
      </div>
    </section>
    <section class="highlight" v-if="metrics">
      <div class="wrapper">
        <div class="highlight-title">
          <img src="@/assets/jms-logo-orange.svg" alt="" class="highlight-title-icon">
          <h2>The number 1 place to find all your favorite venues, gigs, bands and artists!</h2>
        </div>
        <div class="highlight-container">
          <router-link class="highlight-item venues" to="/venues">
            <div class="highlight-item-number">
              <NumberFormat :value="metrics.venues" />
            </div>
            <div class="highlight-item-label">Venues</div>
          </router-link>
          <router-link class="highlight-item bands" to="/bands">
            <div class="highlight-item-number">
              <NumberFormat :value="metrics.bands" />
            </div>
            <div class="highlight-item-label">Bands</div>
          </router-link>
          <router-link class="highlight-item artists" to="/artists">
            <div class="highlight-item-number">
              <NumberFormat :value="metrics.artists" />
            </div>
            <div class="highlight-item-label">Artists</div>
          </router-link>
        </div>
      </div>
    </section>
    <EventCalendar :likes="likes" />
    <section class="leaderboard" v-if="store.state.leaderboards && (store.state.leaderboards.venues.length || store.state.leaderboards.artists.length || store.state.leaderboards.groups.length)">
      <div class="wrapper">
        <div class="section-title with-image">
          <img src="@/assets/jms-ic-top.png" alt="" class="section-title-image">
          <h2>Top Juicy Music Square</h2>
          <div class="section-title-line">Community favorites of the month</div>
          <div class="section-title-note">{{ format(currentDate, 'LLL yyyy') }}</div>
        </div>
        <div class="container">
          <div class="board" v-if="store.state.leaderboards.venues.length">
            <UserList title="Venues" type="venues" :list="store.state.leaderboards.venues"></UserList>
            <router-link class="btn" to="/leaderboard/venues">Explore Top Venues</router-link>
          </div>
          <div class="board" v-if="store.state.leaderboards.artists.length">
            <UserList title="Artists" type="artists" :list="store.state.leaderboards.artists"></UserList>
            <router-link class="btn" to="/leaderboard/artists">Explore Top Artists</router-link>
          </div>
          <div class="board" v-if="store.state.leaderboards.groups.length">
            <UserList title="Bands" type="bands" :list="store.state.leaderboards.groups"></UserList>
            <router-link class="btn" to="/leaderboard/bands">Explore Top Bands</router-link>
          </div>
        </div>
      </div>
    </section>
    <PromotionBanner />
  </div>
</template>

<script lang="ts" setup>
import { Swiper } from 'swiper';
import { nextTick, onBeforeUnmount, onMounted, ref } from 'vue';
import { format } from 'date-fns';
import { Unsubscribe, collection, getCountFromServer, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { useStore } from 'vuex';
import { db } from '@/api';
import UserList from '@/components/UserList.vue';
import PromotionBanner from '@/components/PromotionBanner.vue';
import EventCalendar from '@/components/EventCalendar.vue';
import SmartIcon from '@/components/SmartIcon.vue';
import NumberFormat from '@/components/NumberFormat.vue';
import shuffle from '@/utils/shuffle';
import EventItem from '@/components/EventItem.vue';

import 'swiper/css';

const store = useStore();

const featuredEventsEl = ref();
const featuredEventsRef = ref<Unsubscribe>();
const featuredEventsIndex = ref(0);
const featuredEventsSwiper = ref();
const currentEventsEl = ref();
const currentDate = ref(new Date());
const currentEventsSwiper = ref();
const currentEventsRef = ref();
const likesRef = ref<Unsubscribe>();
const metrics = ref();
const featuredEvents = ref<any[]>([]);
const currentEvents = ref<any[]>([]);
const likes = ref<any[]>([]);

const getFeaturedEvents = () => {
  featuredEventsRef.value?.();
  featuredEventsRef.value = onSnapshot(query(collection(db, 'events'), where('active', '==', true), where('start_date', '>=', new Date()), where('featured', '==', true), orderBy('start_date', 'asc')), (snap) => {
    featuredEvents.value = snap.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        genres: data.genres || [],
        start_date: data.start_date.toDate(),
      };
    });
    nextTick(() => {
      featuredEventsSwiper.value?.destroy();
      const enabled = snap.size > 1;
      featuredEventsSwiper.value = new Swiper(featuredEventsEl.value, {
        slidesPerView: 1,
        centeredSlides: true,
        enabled,
        loop: enabled,
        grabCursor: true,
        breakpoints: {
          680: {
            slidesPerView: enabled ? 1.3 : 1,
          },
        },
        on: {
          realIndexChange(swiper) {
            featuredEventsIndex.value = swiper.realIndex;
          },
        },
      });
    });
  });
};

const getCurrentEvents = () => {
  const now = new Date();
  const start = new Date(now.getFullYear(), now.getMonth(), now.getDate());
  const end = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59, 999);
  currentEventsRef.value = onSnapshot(query(collection(db, 'events'), where('active', '==', true), where('start_date', '>=', start), where('start_date', '<=', end), orderBy('start_date', 'asc')), (snap) => {
    currentEvents.value = shuffle(snap.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        genres: data.genres,
        start_date: data.start_date.toDate(),
      };
    })).slice(0, 10).sort((a, b) => a.start_date - b.start_date);
    nextTick(() => {
      currentEventsSwiper.value?.destroy();
      currentEventsSwiper.value = new Swiper(currentEventsEl.value, {
        slidesPerView: 1.1,
        enabled: snap.size > 1,
        spaceBetween: 12,
        grabCursor: true,
        breakpoints: {
          1024: {
            slidesPerView: 3,
            enabled: snap.size > 3,
          },
          680: {
            slidesPerView: 2,
            enabled: snap.size > 2,
          },
        },
        on: {
          progress: function(swiper: any, progress) {
            if (!swiper.progressBar) return;
            const left = (progress * (swiper.width - swiper.progressBar.clientWidth)) / swiper.width;
            swiper.progressBar.style.left = (left * 100) + '%';
          },
          resize: function(swiper: any) {
            if (!swiper.progressBar) {
              swiper.progressBar = swiper.el.querySelector('.swiper-progress-bar');
              swiper.progressBar.addEventListener('mousedown', function(e: any) {
                swiper.scrollStart = e.pageX;
                swiper.startTranslate = swiper.getTranslate();
                swiper.progressBar.classList.add('pressed');
              });
              window.addEventListener('mouseup', function() {
                if (swiper.progressBar && swiper.progressBar.classList.contains('pressed')) {
                  const index = Math.abs(Math.round(swiper.getTranslate() / swiper.virtualSize * swiper.slides.length));
                  swiper.progressBar.classList.remove('pressed');
                  swiper.slideTo(index, 500);
                }
              });
              window.addEventListener('mousemove', function(e) {
                if (swiper.progressBar && swiper.progressBar.classList.contains('pressed')) {
                  const value = (e.pageX - swiper.scrollStart) / (swiper.progressBar.parentNode.clientWidth - swiper.progressBar.clientWidth);
                  swiper.translateTo(swiper.startTranslate - (value * swiper.virtualSize), 0);
                }
              });
            }
            swiper.progressBar.parentNode.style.display = swiper.virtualSize > swiper.width ? 'block' : 'none';
            swiper.progressBar.style.width = (swiper.width / swiper.virtualSize * 100) + '%';
            const left = (swiper.progress * (swiper.width - swiper.progressBar.clientWidth)) / swiper.width;
            swiper.progressBar.style.left = (left * 100) + '%';
          },
        },
      });
    });
  });
};

const getMetrics = async () => {
  const [venues, artists, bands] = await Promise.all([
    getCountFromServer(query(collection(db, 'venues'))),
    getCountFromServer(query(collection(db, 'artists'))),
    getCountFromServer(query(collection(db, 'groups'))),
  ]);
  metrics.value = {
    venues: venues.data().count,
    artists: artists.data().count,
    bands: bands.data().count,
  };
};

const getLikes = () => {
  likesRef.value?.();
  if (store.state.user) {
    likesRef.value = onSnapshot(query(collection(db, 'likes'), where('target.type', '==', 'event'), where('user.id', '==', store.state.user.id)), (snap) => {
      likes.value = snap.docs.map((doc) => doc.data());
    });
  }
};

onMounted(() => {
  getFeaturedEvents();
  getCurrentEvents();
  getMetrics();
  getLikes();
});

onBeforeUnmount(() => {
  featuredEventsRef.value?.();
  currentEventsRef.value?.();
  likesRef.value?.();
});
</script>

<style scoped>
.featured-event {
  position: relative;
  display: block;
  height: calc(90vh - 112px);
  min-height: 616px;
}

.featured-event .featured-event-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.featured-event .featured-event-detail {
  position: absolute;
  display: flex;
  align-items: stretch;
  left: 72px;
  right: 72px;
  bottom: 48px;
  max-width: 837px;
  margin: 0 auto;
  background: var(--background-dark-opacity);
  opacity: 0;
  transition: all 0.2s ease-out;
  will-change: transform;
}

.featured-event .featured-event-detail:hover {
  transform: scale(1.012);
}

.swiper-slide-active .featured-event .featured-event-detail {
  opacity: 1;
}

.featured-event .featured-event-date {
  width: 92px;
  padding: 24px 8px 12px;
  background: var(--jms-orange);
  text-align: center;
  text-transform: uppercase;
  line-height: 0.7;
}

.featured-event .featured-event-date .day {
  font-size: 3rem;
  font-weight: bold;
}

.featured-event .featured-event-date .month {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 12px 0 16px;
}

.featured-event .featured-event-title-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--jms-light-gray);
  padding-bottom: 4px;
  margin-bottom: 8px;
}

.featured-event .featured-event-title {
  font-size: 2rem;
  font-weight: bold;
}

.featured-event .featured-event-tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.featured-event .featured-event-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 12px 40px 12px 24px;
}

.featured-event .featured-event-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.featured-event .featured-event-location,
.featured-event .featured-event-time {
  display: flex;
  align-items: center;
  gap: 4px;
}

.featured-event .material-icons {
  font-size: 0.9rem;
}

.current-events {
  position: relative;
  overflow: hidden;
}

.current-events .swiper {
  overflow: initial;
}

.event-post {
  position: relative;
  display: block;
  padding-top: 100%;
}

.event-post .event-post-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.event-post .event-post-detail {
  position: absolute;
  display: flex;
  align-items: center;
  left: 4px;
  right: 4px;
  bottom: 4px;
  background: var(--background-dark-opacity);
}

.event-post .event-post-title {
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid var(--jms-light-gray);
  padding-bottom: 4px;
  margin-bottom: 8px;
}

.event-post .event-post-container {
  flex-grow: 1;
  padding: 24px 16px;
}

.event-post .event-post-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.event-post .event-post-location,
.event-post .event-post-time {
  display: flex;
  align-items: center;
  gap: 4px;
}

.event-post .material-icons {
  font-size: 0.9rem;
}

.highlight {
  background: var(--background-darker);
  padding: 40px 0;
}

.highlight .highlight-title {
  display: flex;
  align-items: center;
  gap: 40px;
  font: 400 2.5rem/1.125 'Londrina Solid';
  margin-bottom: 40px;
}

.highlight .highlight-title h2 {
  max-width: 748px;
  font-weight: 400;
}

.highlight .highlight-title-image {
  width: 160px;
}

.highlight .highlight-container {
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.highlight .highlight-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(33.333333% - 8px);
  height: 316px;
  text-align: center;
  background: url(../assets/jms-highlight-venues.png) no-repeat center/cover;
  line-height: 1;
}

.highlight .highlight-item.bands {
  background-image: url(../assets/jms-highlight-bands.png);
}

.highlight .highlight-item.artists {
  background-image: url(../assets/jms-highlight-artists.png);
}

.highlight .highlight-item-number {
  font-size: 6rem;
  font-weight: bold;
}

.highlight .highlight-item-label {
  font-size: 2rem;
  font-weight: bold;
  color: var(--jms-yellow);
}

.leaderboard .container {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.leaderboard .board {
  width: calc(33.333333% - 16px);
  text-align: center;
}

.leaderboard .section-title img {
  width: 98px;
}

.leaderboard .board > .btn {
  margin-top: 40px;
}

.slider-pagination {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 3;
  will-change: transform;
}

.slider-pagination .slider-bullet {
  width: 10px;
  height: 10px;
  padding: 0;
  border: 1px solid #fff;
}

.slider-pagination .slider-bullet.active {
  background: var(--jms-orange);
}

.current-events .swiper {
  padding-bottom: 32px;
}

.swiper a {
  cursor: inherit;
}

.swiper .swiper-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 8px;
  background: var(--background-darker);
  border-radius: 4px;
}

.swiper .swiper-progress .swiper-progress-bar {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background: var(--background-light);
  transition: left 0.2s ease;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;
}

.swiper .swiper-progress .swiper-progress-bar.pressed {
  transition: none;
}

@media screen and (max-width: 1024px) {
  .featured-event {
    min-height: 0;
    height: 67vw;
  }
  .leaderboard .board {
    width: 100%;
  }
}

@media screen and (max-width: 840px) {
  .featured-event {
    min-height: 0;
    height: 67vw;
  }
  .featured-event .featured-event-detail {
    left: 24px;
    right: 24px;
  }
}

@media screen and (max-width: 680px) {
  .highlight .highlight-title {
    font-size: 2rem;
  }
  .highlight .highlight-title .highlight-title-icon {
    width: 110px;
  }
  .highlight .highlight-item {
    width: 100%;
    height: 50vw;
  }
  .featured-event {
    min-height: 450px;
  }
  .featured-event .featured-event-title-container {
    flex-direction: column;
    align-items: flex-start;
    padding-bottom: 8px;
  }
  .featured-event .featured-event-title {
    font-size: 1.5rem;
  }
  .featured-event .featured-event-container {
    padding: 12px 16px;
  }
  .featured-event .featured-event-detail {
    left: 8px;
    right: 8px;
  }
}

@media screen and (max-width: 480px) {
  .highlight .highlight-title {
    display: block;
  }
  .highlight .highlight-title h2 {
    margin-top: 16px;
  }
}
</style>
