<template>
  <div class="select-container">
    <button class="input" type="button" :disabled="disabled" @click.stop="open = !open">
      {{ selected ? selected.label : placeholder || '' }}
    </button>
    <div class="select-options" v-if="open && !disabled">
      <button
        v-for="option in options"
        :class="`select-option${modelValue === option.value ? ' selected' : ''}`"
        type="button"
        :key="option.value"
        :style="{ color: option.color }"
        @click.stop="emit('update:modelValue', option.value); open = false"
      >
        {{ option.label }}
      </button>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, defineProps, defineEmits, computed, onMounted, onBeforeUnmount } from 'vue';

interface SelectInputOption {
  label: string | number;
  value: string | number;
  color?: string;
}

const open = ref(false);

const props = defineProps<{
  modelValue?: string | number,
  options: SelectInputOption[],
  placeholder?: string,
  disabled?: boolean,
}>();

const emit = defineEmits(['update:modelValue']);

const close = () => {
  open.value = false;
};

const selected = computed(() => props.options.find((option) => option.value === props.modelValue) || null);

onMounted(() => {
  window.addEventListener('click', close);
});

onBeforeUnmount(() => {
  window.removeEventListener('click', close);
});
</script>

<style scoped>
.select-container {
  position: relative;
  display: inline-block;
}

.select-container .input {
  height: 32px;
  background: url(../assets/ic-chevron-down.svg) no-repeat right center/18px 18px;
  padding-right: 24px;
}

.select b {
  margin: 0 6px;
}

.select i {
  opacity: 0.69;
}

.select-options {
  position: absolute;
  top: 100%;
  right: 0;
  background: var(--background-black-opacity);
  z-index: 10;
  padding: 4px 0;
  min-width: 100%;
  overflow: hidden;
}

.select-option {
  display: block;
  width: 100%;
  padding: 4px 16px;
}

.select-option.selected {
  font-weight: bold;
}

.select-option:hover {
  background: var(--background-light-opacity);
}
</style>
