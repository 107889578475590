import { createStore } from 'vuex';
import { signInWithCustomToken, onAuthStateChanged, signOut } from 'firebase/auth';
import { Unsubscribe, collection, doc, limit, onSnapshot, orderBy, query, where } from 'firebase/firestore';
import { auth, db } from '../api';
import { client } from '../api/api';

const store = createStore({
  state: {
    user: undefined,
    artist: null,
    venue: null,
    authUser: null,
    leaderboards: null,
    notifications: [],
    currencies: {
      eur: '€',
      usd: '$',
      gbp: '£',
    },
    plans: {
      fan: {
        name: 'Fan',
        price: 0,
        buttonText: 'Sign up',
      },
      venue: {
        name: 'Venue',
        price: 69,
        buttonText: 'Get started',
      },
      artist: {
        name: 'Artist',
        price: 15,
        buttonText: 'Get started',
      },
      band: {
        name: 'Band',
        price: 30,
        buttonText: 'Get started',
      },
    },
  },
  mutations: {
    setUser(state, user) {
      state.user = user || null;
    },
    setArtist(state, artist) {
      state.artist = artist || null;
    },
    setVenue(state, venue) {
      state.venue = venue || null;
    },
    setAuthUser(state, user) {
      state.authUser = user || null;
    },
    setLeaderboards(state, leaderboards) {
      state.leaderboards = leaderboards;
    },
    setNotifications(state, notifications) {
      state.notifications = notifications;
    },
  },
});

let params = new URLSearchParams(window.location.search);
let token = params.get('token');
let userRef: Unsubscribe;
let notificationsRef: Unsubscribe;

if (token && window.history.pushState) {
  params.delete('token');
  const qs = params.toString();
  const url = `${window.location.origin}${window.location.pathname}${qs ? `?${qs}` : ''}`;
  window.history.pushState({ path: url }, '', url);
}

const getLeaderboards = async () => {
  try {
    const leaderboards = await client.get('/leaderboards/monthly');
    store.commit('setLeaderboards', leaderboards);
  } catch (error) {
    console.warn(error);
  }
};

const startTime = new Date();

onAuthStateChanged(auth, async (authState) => {
  try {
    userRef?.();
    let authUser;
    if (token) {
      const session = await signInWithCustomToken(auth, token);
      token = null;
      authUser = session.user;
    } else if (authState) {
      authUser = authState;
    }
    store.commit('setAuthUser', authUser);
    client.setUser(authUser || null);
    if (authUser) {
      params = new URLSearchParams(window.location.search);
      const user = await client.post('/account', { referral: params.get('referral') || '' });
      if (Date.now() - startTime.getTime() < 10000 && !user.onboarding) {
        signOut(auth);
        return;
      }
      updateUser(user);
    } else {
      store.commit('setUser', null);
    }
  } catch (error: any) {
    token = null;
    console.warn(error.message);
    signOut(auth);
  }
});

export const updateUser = (user: any) => {
  userRef?.();
  notificationsRef?.();
  if (user.type === 'artist') {
    store.commit('setVenue', null);
    userRef = onSnapshot(doc(db, `artists/${user.id}`), (snap) => {
      store.commit('setArtist', snap.data());
      store.commit('setUser', user);
    });
  } else if (user.type === 'venue') {
    store.commit('setArtist', null);
    userRef = onSnapshot(doc(db, `venues/${user.id}`), (snap) => {
      store.commit('setVenue', snap.data());
      store.commit('setUser', user);
    });
  } else {
    store.commit('setVenue', null);
    store.commit('setArtist', null);
    store.commit('setUser', user);
  }
  notificationsRef = onSnapshot(query(collection(db, 'notifications'), where('user_id', '==', user.id), orderBy('created_at', 'desc'), limit(20)), (snap) => {
    const notifications = snap.docs.map((doc) => {
      const data = doc.data();
      return {
        ...data,
        created_at: data.created_at.toDate(),
      };
    });
    store.commit('setNotifications', notifications);
  });
};

getLeaderboards();

export default store;
